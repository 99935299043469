import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";
import InitializeGoogleAnalytics from "./helpers/analytics.js";
// import { ErrorBoundary } from "react-error-boundary";
import usePost from "./hooks/usePost.js";

function ErrorFallback(props) {
  const errMsg = props?.error?.toString();
  const errStackStr = props?.error?.stack?.toString();

  const { makeRequest } = usePost();
  useEffect(() => {
    const userAgent = window?.navigator?.userAgent + ""
    // make api call to log error
    const href = window.location.href;
    try {
      makeRequest("/v1/misc/log", {
        type: "error",
        info: {
          stack: errStackStr,
          message: errMsg,
          userAgent,
          href,
          referrer: document.referrer
        }
      })
    } catch (error) {
      makeRequest("/v1/misc/log", {
        type: "catchErrLErrBoundry",
        info: {
          message: error.toString(),
          userAgent,
          href,
          referrer: document.referrer
        }
      });
    }
  }, [errMsg, errStackStr])

  return (
    <div className="h-screen w-full flex justify-center items-center">
      <div className="text-center">
        <h2>Something went wrong.</h2>
        <p>Please click on below button to go to home page</p>
        <a href="/">
          <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4">
            Go to Home
          </button>
        </a>
      </div>
    </div>
  );
}

InitializeGoogleAnalytics();

if (window.location.hostname === "cricketgully.com") {
  Sentry.init({
    // dsn: "https://c1fb2b202f91be16e12d17dd8088c1a5@o4506919549927424.ingest.us.sentry.io/4507146244456448", //RV Account
    dsn: "https://5d838b10713aaa7f066a1950cd4728a9@o4508999961346048.ingest.us.sentry.io/4509016972328960",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.1, //  Capture 100% of the transactions
    environment: "production", // APP_ENV,
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/api\.cricketgully.com/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 0.1, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}
ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    {/* <ErrorBoundary FallbackComponent={ErrorFallback}> */}
    <Sentry.ErrorBoundary fallback={<PureErrorBoundry />}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Sentry.ErrorBoundary>
  </React.StrictMode>
);

function PureErrorBoundry() {
  return (
    <div className="h-screen w-full flex justify-center items-center">
      <div className="text-center">
        <h2>Something went wrong.</h2>
        <p>Please click on below button to go to home page.</p>
        <a href="/">
          <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4">
            Go to Home
          </button>
        </a>
      </div>
    </div>
  );
}