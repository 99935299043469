import React, { useEffect, useRef, useState } from 'react'
import SEOMeta from '../../Home/common/SEOMeta';
import { Link } from 'react-router-dom';
import { getAssetUrl, getSanitizedArray, isLiveNewsTag } from '../../../../helpers/general';
import { formatDate } from '../../../../helpers/dates';
import { getMobileDetect, showGoogleAd, SlotProps } from '../../../../constants';
import GoogleAdUnit from '../../../common/GoogleAdUnit';
import { NewsFAQs } from '../newsFAQs';
import MoreStories from '../../../common/MoreStories';
import KeyEvents from './KeyEvents';
import { useAxiosSWR } from '../../../../../axiosInstance';
import EventBlock from './EventBlock';
import { useLoadSocialMediaOnce } from '../../../../hooks/useLoadSocialMediaOnce';

const view = getAssetUrl("authorsInstanceViews.svg");

export default function LiveBlogInstance({ data, newsId }) {

    const [seoMeta, setSeoMeta] = useState(null);
    const { reprocessEmbeds } = useLoadSocialMediaOnce();
    const author = data?.author;
    const isFantasyNews = data?.type === 'fantasy';
    const customBlocks = data?.info?.customBlocks;
    const { hash } = window.location;
    const newsType = data?.type;

    const {
        data: liveBlocksData,
        isLoading,
        error,
        mutate: refetchNewsBlocks
    } = useAxiosSWR(`/v1/news/${newsId}/newsblocks`);

    useEffect(() => {
        const scrollToElement = () => {
            if (hash) {
                const element = document.getElementById(hash.substring(1));
                if (element) {
                    const navbarHeight = 68;
                    const yOffset = -navbarHeight;
                    const y = element.getBoundingClientRect().top + window.scrollY + yOffset;
                    window.scrollTo({ top: y, behavior: 'smooth' });
                    window.history.replaceState(null, null, ' ');
                }
            }
        };
    
        const timer = setTimeout(() => {
            requestAnimationFrame(scrollToElement);
        }, 2000);
    
        return () => clearTimeout(timer);
    }, [hash, liveBlocksData]);

    useEffect(() => {
        let timerId;
        timerId = setInterval(() => {
            console.log('Refetching live blocks at ' + (new Date().toString()))
            refetchNewsBlocks();
        }, 10000);

        return () => {
            clearInterval(timerId);
        }
    }, [])

    const sanitizedBlocks = getSanitizedArray(liveBlocksData);

    useEffect(() => {
        for (let i = 0; i < sanitizedBlocks.length; i++) {
            const content = sanitizedBlocks[i]?.body;
            if ((content + "").includes("twitter") || (content + "").includes("www.instagram")) {
                reprocessEmbeds();
                break;
            }
        }
    }, [sanitizedBlocks]);

    useEffect(() => {
        if (data?.info) {
            setSeoMeta({
                title: data?.info?.metaTitle || data?.title,
                description: data?.info?.metaDescription,
                image: data?.image
            });
        }
    }, [data]);

    const md = getMobileDetect();
    const isMobile = Boolean(md.mobile());

    if (!data) return null;

    return (
        <>
            {seoMeta && <React.Suspense><SEOMeta data={seoMeta} /></React.Suspense>}
            <div className="bg-white rounded-2xl border md:border-none py-4 md:py-6 mx-[6px] md:mx-5">
                <div className="text-[#646464] md:text-sm text-[9px] inline-block px-4 md:px-10">
                    <Link to={"/"} className="text-[#3A32D1]"> Home </Link>
                    <span>{">"}</span>
                    <span className="ml-1 text-[#3A32D1]">Live Blog</span>
                </div>
                <div className='px-4 md:px-10'>
                    <h1
                        className={`${data?.title?.split(" ")?.length >= 22 && isFantasyNews ? 'md:text-[24px]' : 'md:text-3xl'} font-semibold text-lg pt-2 pb-3 md:leading-[32px] leading-[27px]`}
                    >{data.title}</h1>
                    <span className="block border-b py-1"></span>
                    <div data-testid="all_device_container" className="flex items-center justify-between mt-3">
                        <div className="flex flex-wrap items-center gap-3">

                            <p data-testid="mobile_published_at" className='flex'>
                                {(data.publishedAt || data.createdAt) && <span className="lg:text-sm text-[#00000080] text-[10px] font-semibold">{formatDate(data.publishedAt || data.createdAt)}</span>}
                            </p>
                            <p className="lg:text-sm text-[#6c6c6c96] text-[10px] font-semibold">
                                {author?.username ? <Link className="text-[#3A32D1]" to={`/authors/${author?.username}`}>By {data.author.name}</Link> : `By ${author?.name || "CricketGully"}`}
                            </p>
                        </div>
                        <div className='flex items-center gap-2 text-center'>
                            <div className='w-max mx-auto'>
                                <img src={view} alt="" />
                            </div>
                            <div className='text-[10px] md:text-[12px] md:font-medium text-[#B2B3B5]'>
                                Views
                            </div>
                            <div className='text-[10px] md:text-[12px] md:font-medium text-[#6C6C6C] text-center'>
                                {data?.cnt_views ? data?.cnt_views?.toLocaleString("en-IN") : 0}
                            </div>
                        </div>
                    </div>
                </div>
                {showGoogleAd ?
                    <>
                        {isMobile ?
                            <div className="flex justify-center overflow-x-hidden overflow-y-hidden"> {/* max-h-[260px] */}
                                <GoogleAdUnit insCustomClass='mt-2' slotProps={SlotProps.Mobile_News_Details_Below_Image} />
                            </div>
                            :
                            <div className="flex justify-center overflow-x-hidden">
                                <GoogleAdUnit insCustomClass='mt-2' slotProps={SlotProps.Desktop_News_Details_Below_Image} />
                            </div>
                        }
                    </> : null
                }

                {sanitizedBlocks ?
                    <div className='mx-[6px]'>
                        <KeyEvents data={sanitizedBlocks} />
                    </div> : null
                }

                <div className='overflow-hidden md:mt-5'>
                    {sanitizedBlocks && sanitizedBlocks.map((block, index) => (
                        <div id={block.id} className='px-2 pl-5 md:mx-10 md:px-10 my-3 py-1 relative isLiveEventBlock' key={block.updatedAt}>
                            <EventBlock 
                                id={block.id} 
                                title={block.title} 
                                body={block.body} 
                                blockTime={block.blockTime}
                                showTimeAgo={index === 0} 
                            />
                            {sanitizedBlocks.length - 1 === index ? '' :
                                <span className='block border-b border-[#E1E2E2] mt-4'></span>
                            }
                        </div>
                    ))}
                </div>

                <NewsFAQs content={data?.info?.faqs} />
                {((isFantasyNews && data?.match_id) || (Array.isArray(customBlocks) && customBlocks.length > 0)) &&
                    <div className="md:px-10">
                        <React.Suspense><NewsMatchInfo newsObj={data} /></React.Suspense>
                    </div>
                }
            </div>
            <div className="mt-4 md:mx-5 mx-[6px]">
                <React.Suspense><MoreStories newsType={newsType} currentNewsID={newsId} /></React.Suspense>
            </div>
        </>
    )
}
