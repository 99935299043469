import { Helmet } from "react-helmet";
import { useAxiosSWR } from "root/axiosInstance";

export function SEOMeta ({data, slug}) {
    const {
        data: metaData,
        isLoading: loadingMeta,
        error: isMetaError,
    } = !data ? useAxiosSWR(`/v1/misc/meta/${slug}`) : {data, isLoading: false, error: false};
    //Always consider current page url as canonicalURL
    let canonicalURL = window.location.href; //metaData && metaData.canonicalURL ? metaData?.canonicalURL : window.location.href;
    if(canonicalURL.indexOf("http")==-1){
        canonicalURL=`${window.location.protocol}//${window.location.host}${canonicalURL}`
    }

    if (canonicalURL.includes("/web-stories/")) {
       const hashIndex = canonicalURL.indexOf("#");
       if (hashIndex !== -1) {
         canonicalURL = canonicalURL.slice(0, hashIndex);
       }
       if (canonicalURL.endsWith("/")) {
        canonicalURL += "amp";
       } else {
        canonicalURL = canonicalURL + "/amp";
       }
    }
    if (metaData) {
        return (
            <Helmet>
                <title>{metaData?.title}</title>
                <meta name="description" content={metaData?.description} />
                <meta property="og:title" content={metaData?.title} />
                <meta property="og:description" content={metaData?.description} />
                {metaData?.keywords && <meta property="keywords" content={metaData?.keywords} />}
                {metaData?.image && <meta property="og:image" content={metaData?.image} />}
                <link rel="canonical" href={canonicalURL} />
            </Helmet>
        );
    }
};

export default SEOMeta;