import React, { useContext, useEffect, useState } from 'react'
import PlayerOverview from './PlayerOverview'
import NewsV2 from '../../NewsV2'
import { useNavigate, useParams } from 'react-router-dom'
import { useAxiosSWR } from '../../../../axiosInstance'
import AppContext from '../../../context'

export default function PlayersProfile() {
    const { state, updatePlayerInfo } = useContext(AppContext);

    const { playerSlug } = useParams();
    const playerId = playerSlug.split('-').pop();
    const { playerSection } = useParams();
    const navigate = useNavigate();

    const { data, isLoading, error, mutate } = useAxiosSWR(playerId ? `/v1/players/${playerId}/stats` : null);

    useEffect(() => {
        updatePlayerInfo(data, isLoading, error, mutate);
    }, [data, isLoading, error, mutate, updatePlayerInfo]);

    useEffect(() => {
        if (!playerSection) {
            navigate(`/players-details/${playerSlug}/overview`, { replace: true });
        }
    }, [playerSection, playerSlug, navigate]);

    const renderSection = () => {
        if (playerSection === 'overview') {
            return <PlayerOverview data={data} playerSection="overview" />;
        } else if (playerSection === 'stats') {
            return <PlayerOverview data={data} playerSection="stats" />;
        } else if (playerSection === 'news') {
            return <NewsV2 />;
        } else {
            return <div>Section not found</div>;
        }
    };

    if (isLoading) {
        return <div className='flex justify-center items-center w-full h-screen text-xl'>Loading...</div>
    }

    if (error) {
        return (
            <div className='flex flex-col justify-center items-center w-full h-full text-xl text-center'>
                something went wrong fetching player details
                <button onClick={mutate} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4 w-fit mx-auto text-lg">
                    Try Again
                </button>
            </div>
        )
    }

    return (
        <>
            <div className='max-w-[1320px] xl:mx-auto'>
                {/* tabs */}
                <div className='hidden md:block bg-white p-5 rounded-[28px] md:mx-0 mx-[6px] mt-5 border border-[#E3E3E4]'>
                    <div className='grid text-center p-[1px] items-center grid-cols-3 gap-4'>
                        <button
                            className={`text-black font-bold text-base border-2 rounded-[14px] py-3 ${playerSection === 'overview' ? 'bg-[#3A32D1] text-white border-[#3A32D1]' : 'border-[#eee]'}`}
                            onClick={() => {
                                navigate(`/players-details/${playerSlug}/overview`)
                            }}
                        >
                            Overview
                        </button>
                        <button
                            className={`text-black font-bold text-base border-2 rounded-[14px] py-3 ${playerSection === 'stats' ? 'bg-[#3A32D1] text-white border-[#3A32D1]' : 'border-[#eee]'}`}
                            onClick={() => {
                                navigate(`/players-details/${playerSlug}/stats`)
                            }}
                        >
                            Stats
                        </button>
                        <button
                            className={`text-black font-bold text-base border-2 rounded-[14px] py-3 ${playerSection === 'news' ? 'bg-[#3A32D1] text-white border-[#3A32D1]' : 'border-[#eee]'}`}
                            onClick={() => {
                                navigate(`/players-details/${playerSlug}/news`)
                            }}
                        >
                            News
                        </button>
                    </div>
                </div>

                <div className='my-5'>
                    {renderSection()}
                </div>
            </div>

        </>
    )
}
