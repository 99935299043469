import React from "react";
import { Link, useLocation } from "react-router-dom";
import { doScrollToTop, getAssetUrl } from "../../helpers/general";

const links = [
  {
    label: "Home",
    target: "/",
    icon: getAssetUrl("icons-footer/home.svg"),
    route: "",
    iconUnSelect: getAssetUrl("icons-footer/home-unselect.svg"),
  },
  {
    label: "Web Stories",
    target: "/web-stories",
    icon: getAssetUrl("icons-footer/web-stories.svg"),
    route: "web-stories",
    iconUnSelect: getAssetUrl("icons-footer/web-stories-unselect.svg"),
  },
  // {
  //   label: "Series",
  //   target: "/series",
  //   icon: getAssetUrl("icons-footer/series.svg"),
  //   route: "series",
  //   iconUnSelect: getAssetUrl("icons-footer/series-unselect.svg"),
  // },
  // {
  //   label: "News",
  //   target: "/cricket-news",
  //   icon: getAssetUrl("icons-footer/news.svg"),
  //   route: "news",
  //   iconUnSelect: getAssetUrl("icons-footer/news-unselect.svg"),
  //   id: "cricket"
  // },
  {
    label: "Fantasy",
    target: "/fantasy-cricket-tips",
    icon: getAssetUrl("icons-footer/fantasy.svg"),
    route: "fantasy-cricket-tips",
    iconUnSelect: getAssetUrl("icons-footer/fantasy-unselect.svg"),
  },
  {
    label: "Schedule",
    target: "/schedule/live",
    icon: getAssetUrl("icons-footer/schedule.svg"),
    route: "schedule",
    iconUnSelect: getAssetUrl("icons-footer/schedule-unselect.svg"),
  },
  {
    label: "More",
    target: "/more-categories",
    icon: getAssetUrl("icons-footer/more.svg"),
    route: "more-categories",
    iconUnSelect: getAssetUrl("icons-footer/more-unselect.svg"),
  },
];

const MobileNavbar = ({ navContainerRef }) => {
  const location = useLocation();
  const pathname = location.pathname.split("/")[1];

  return (
    <div ref={navContainerRef} className="bg-[#FFFFFF] h-[84px] rounded-t-xl shadow-sm">
      <div className="grid grid-cols-5 h-16 place-content-center">
        {links.map(({ label, target, icon, iconUnSelect, route, id }, i) => (
          <div key={i} className="h-full w-full ">
            <Link
              to={target}
              onClick={doScrollToTop}
              className="block h-16 py-3"
            >
              <img
                src={icon}
                alt={label}
                className={`mx-auto ${pathname.includes(id) || pathname === route ? 'block' : 'hidden'}`}
              />
              <img
                src={iconUnSelect}
                alt={label}
                className={`mx-auto ${pathname.includes(id) || pathname === route ? 'hidden' : 'block'}`}
              />
              <p className="text-xs w-max mx-auto">{label}</p>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MobileNavbar;
