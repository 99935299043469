import React, { useEffect, useMemo, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { formatDate } from '../../../helpers/dates'
import { useAxiosSWR } from '../../../../axiosInstance'
import usePost from '../../../hooks/usePost'
import { getMobileDetect } from '../../../constants'
import LiveAnimatedRedDot from '../../common/LiveAnimatedRedDot'
import { convertViews, getAssetUrl, isLiveNewsTag } from '../../../helpers/general'

const view = getAssetUrl("authorsInstanceViews.svg");
const post = getAssetUrl('postsicon.svg');
const views = getAssetUrl('viewsicon.svg');

const typeToText = {
  FantasyArticles: 'fantasy',
  NormalArticles: 'news',
};
const allNews = 'All'
const perPage = 10;
const AuthorsInstance = () => {
  const [myState, setMyState] = useState({
    currentPage: 1,
    filterBtn: allNews,
    newsList: [],
  });

  const { currentPage, filterBtn, newsList } = myState;
  const { username } = useParams();
  const reqObjAuthor = usePost();
  const { data, isLoading, error } = reqObjAuthor;
  const authorData = data?.data;
  const url = authorData?.id ? `/v1/authors/${authorData?.id}/news?page=${currentPage}&per_page=${perPage}&type=${filterBtn === allNews ? '' : typeToText[filterBtn]}` : null;
  const { data: newsData, isLoading: isNewsLoading, error: newsError, mutate: retryNewsFetch } = useAxiosSWR(url);

  useEffect(() => {
    fetchAuthorsData();
  }, [])

  const fetchAuthorsData = () => reqObjAuthor.makeRequest("v1/authors/findByUsername", { username });

  const totalPages = newsData?.total_pages;
  const apiResponseData = newsData?.data;
  const totalContent = newsData?.total;

  useEffect(() => {
    if (Array.isArray(apiResponseData)) {
      setMyState(prev => ({
        ...prev,
        newsList: [...prev.newsList, ...apiResponseData],
      }))
    }
  }, [apiResponseData])

  const md = getMobileDetect();
  const isMobile = Boolean(md.mobile())

  if (isLoading) {
    return <div className='container mx-auto px-[6px] md:px-4 py-8 break-words h-[144px]'><h2>Loading...</h2></div>
  }

  if (error) {
    return <div className='container mx-auto px-[6px] md:px-4 py-8 break-words h-[144px]'>
      <h2>Error fetching data</h2>
      <button className='bg-[#3A32D1] rounded-md p-1 text-white font-semibold px-3 hover:text-gray-300 transition ease-linear mt-3' onClick={fetchAuthorsData}>Try Again</button>
    </div>
  }

  return (
    <div>
      <div className='mx-1 md:mx-[10px] lg:mx-[10px] xl:mx-[140px] md:my-[80px] mt-1 mb-[40px] md:rounded-[20px] md:py-10 py-6 md:px-[30px]'>
        <div className='bg-white border rounded-[20px] md:p-8 p-3 py-6'>
          <div className="text-[#646464] md:text-sm text-[10px] inline-block md:px-10">
            <Link to={"/"} className="text-[#3A32D1]"> Home </Link>
            <span>{">"}</span>
            <Link to={'/authors'}>
              <span className="ml-1 text-[#3A32D1]">Author</span>
            </Link>
            <span>{">"}</span>
            <span className="ml-1 text-[#3A32D1]">{authorData?.name}</span>
          </div>
          <div className='bg-[#EDF4FF] p-3 md:p-6 mt-6 rounded-2xl'>
            <div className='flex items-center'>
              <img src={authorData?.info?.profile_pic} alt="" className='object-cover	h-[60px] md:h-[122px] w-[60px] md:w-[122px] rounded-xl' />
              <div className='flex items-center justify-between w-full'>
                <div className='md:p-4 p-[11px]'>
                  <div className='font-bold text-[15px] leading-[15px] md:text-[32px] md:leading-[32px]'>{authorData?.name}</div>
                  <div className='flex-col md:flex-row md:flex gap-1 items-center md:items-start md:leading-[20px] mt-2 md:mt-4 text-[10px] md:text-[14px] text-[#6C6C6C]'>
                    {authorData?.info?.designation} {authorData?.info?.designation && !isMobile && '•'}
                    <div className='flex items-center gap-1'>
                      <span><img src={post} alt="" /></span>
                      {authorData?.stats?.total_articles} posts
                      <span className='md:hidden'>•</span>
                      <span className='md:hidden'><img src={views} alt="" /></span>
                      <span className='md:hidden'> {convertViews(authorData?.stats?.total_views)} Views</span>
                    </div>
                  </div>
                  <div className='text-[18px] font-normal leading-[18px] mt-[22px] hidden md:block'>{authorData?.info?.bio}</div>
                </div>
                <div className='hidden md:block pr-4'>
                  <div className='w-max mx-auto'>
                    <img src={view} alt="" />
                  </div>
                  <div className='text-[10px] md:text-[12px] md:font-medium text-[#6C6C6C] text-center'>
                    {authorData?.stats?.total_views ? convertViews(authorData?.stats?.total_views) : 0}
                  </div>
                  <div className='text-[10px] md:text-[12px] md:font-medium text-[#B2B3B5]'>
                    Views
                  </div>
                </div>
              </div>
            </div>
            <div className='text-[12px] font-normal leading-[16px] mt-[20px] md:hidden'>{authorData?.info?.bio}</div>
          </div>
        </div>
        <div className='bg-white border rounded-[20px] mt-[16px] md:mt-[28px] md:p-[32px] py-[24px] px-[16px]'>
          <div className='flex text-center gap-3'>
            <div className={`flex justify-center items-center md:w-[185px] px-6 md:px-0 border md:rounded-[14px] rounded-[8px] text-[10px] md:text-[16px] leading-4 py-[12px] md:py-[15px] cursor-pointer ${filterBtn === allNews ? "bg-[#3A32D1] text-white border-none" : ""}`} onClick={() => {
              setMyState({
                filterBtn: allNews,
                newsList: [],
                currentPage: 1,
              });
            }}>All</div>
            <div className={`md:w-[185px] px-6 md:px-0 border md:rounded-[14px] rounded-[8px] text-[10px] md:text-[16px] leading-4 py-[12px] md:py-[15px] cursor-pointer ${filterBtn === "NormalArticles" ? "bg-[#3A32D1] text-white border-none" : ""}`} onClick={() => {
              setMyState({
                filterBtn: 'NormalArticles',
                newsList: [],
                currentPage: 1,
              });
            }}>News Articles</div>
            <div className={`md:w-[185px] px-6 md:px-0 border md:rounded-[14px] rounded-[8px] text-[10px] md:text-[16px] leading-4 py-[12px] md:py-[15px] cursor-pointer ${filterBtn === "FantasyArticles" ? "bg-[#3A32D1] text-white border-none" : ""}`} onClick={() => {
              setMyState({
                filterBtn: 'FantasyArticles',
                newsList: [],
                currentPage: 1,
              });
            }}>Fantasy Articles</div>
          </div>
          <div className='mt-2'>
            {isNewsLoading && newsList.length === 0 ? <div className='mt-4'>Loading....</div> : newsList?.length === 0 ? <div className='mt-4'>No articles found in this category</div> : null}
            {newsList?.map((obj, i) => (
              <Link key={`${obj.id}_${i}`} to={obj?.href} target='_blank'>
                <div className={`flex py-4 md:py-[30px] ${i === newsList.length - 1 ? "pb-0" : "border-b"}`}>
                  <div>
                    <img src={obj.image} alt="img" className='md:min-w-[146px] min-w-[71px] max-w-[71px] h-[51px] md:max-w-[146px] md:h-[104px] rounded-[14px]' />
                  </div>
                  <div className='md:pt-[8px] pl-3 md:pl-6'>
                    <div className='font-semibold text-[12px] md:text-[18px] leading-[14px] md:leading-[24px] news-title min-h-[28px] md:min-h-[54px] md:pr-[130px] pr-6'>
                      {obj.title}
                    </div>
                    <div className='flex items-center text-[#999999] text-[10px] md:text-[16px] font-medium mt-1 md:mt-[14px]'>
                      {isLiveNewsTag(obj?.isLive) && <LiveAnimatedRedDot className='me-2' />}
                      {formatDate(obj.date, true)}
                    </div>
                  </div>
                  <div className='text-center ml-auto'>
                    <div className='w-max mx-auto'>
                      <img src={view} alt="" />
                    </div>
                    <div className='text-[10px] md:text-[12px] md:font-medium text-[#6C6C6C]'>
                      {obj.views}
                    </div>
                    <div className='text-[10px] md:text-[12px] md:font-medium text-[#B2B3B5]'>
                      {convertViews(obj?.cnt_views)}
                      <span className='block'>Views</span>
                    </div>
                  </div>
                </div>
              </Link>
            ))
            }
            {newsError &&
              <div className='mt-10'>
                <h2>Error fetching content.</h2>
                <button className='bg-[#3A32D1] rounded-md p-1 text-white font-semibold px-3 hover:text-gray-300 transition ease-linear mt-3' onClick={retryNewsFetch}>Try Again</button>
              </div>
            }
            {(!newsError && newsList.length !== totalContent) ?
              <LoadMoreButton fakeLoading={isNewsLoading} onClick={() => {
                if (currentPage < totalPages) {
                  setMyState(prev => ({
                    ...prev,
                    currentPage: currentPage + 1,
                  }))
                }
              }} />
              : null
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default AuthorsInstance

function LoadMoreButton({ fakeLoading, onClick }) {
  return (
    <div className='flex justify-center'>
      <button disabled={fakeLoading} className={`bg-[#3A32D1] text-[#fff] rounded-[50px] px-4 py-2 mt-2 shadow-sm font-medium ${fakeLoading ? ' opacity-75 ' : ''}`} onClick={onClick}>
        {fakeLoading ? 'Fetching....' : 'Load More'}
      </button>
    </div>
  )
}