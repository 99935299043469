import React, { useState } from 'react'
import usePost from '../hooks/usePost';

export default function PurgeCache() {

    const reqObjContactus = usePost();
    const { isLoading } = reqObjContactus;

    const [files, setFiles] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [isError, setIsError] = useState('');

    const handleChange = (e) => {
        e.preventDefault();
        setIsError('');
        setFiles(e.target.value);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!files.trim()) {
            setIsError('Files URL is required');
            return;
        }
        const filesArray = files.split('\n').map(url => url.trim()).filter(url => url);
        const response = await reqObjContactus.makeRequest("/v1/cache/purge-cache", { files: filesArray });
        if (response?.status === "success") {
            setFiles('');
            setSuccessMessage("Files are purged.");
        }
    };

    return (
        <div className='max-w-[1300px] mx-auto py-5'>
            <form
                className={`bg-white border rounded-3xl mx-[6px] md:mx-[80px] lg:mx-[140px] xl:mx-[180px] mt-4 md:rounded-[20px] md:py-[54px] py-6 md:px-[54px] px-[20px] ${successMessage ? "" : "md:my-[80px] "}`}
                onSubmit={handleSubmit}
            >
                {successMessage &&
                    <div className='bg-green-100 border border-green-400 text-green-700 px-4 rounded mb-5'>
                        {successMessage}
                    </div>
                }
                {isError && (
                    <div className='bg-red-100 border border-red-400 text-red-700 px-4 rounded mb-5'>
                        {isError}
                    </div>
                )}
                <div className='col-start-1 col-end-4'>
                    <textarea
                        className='border rounded-xl p-4 md:p-5 block mt-4 md:mt-0 w-full'
                        placeholder='Files URL'
                        name='files'
                        value={files}
                        onChange={handleChange}
                        rows={8}
                    ></textarea>
                </div>
                <button
                    type="submit"
                    className='border rounded-xl p-4 md:p-5 bg-[#3A32D1] text-white mt-9 inline-block w-1/2 md:w-1/4'
                    disabled={isLoading}
                >
                    {isLoading ? 'Submitting...' : 'Submit'}
                </button>
            </form>
        </div>
    )
}
