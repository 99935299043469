import React, { useState, useEffect } from 'react';

const CookieConsent = () => {
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        // Check if the user has already given consent
        const consentGiven = localStorage.getItem('cookieConsent');
        if (!consentGiven) {
            setIsVisible(true); // Show popup if consent not found
        }else{
            setIsVisible(false); // hide popup if consent found
        }
    }, []);

    const handleConsent = () => {
        localStorage.setItem('cookieConsent', 'true'); // Store consent flag in localStorage
        setIsVisible(false); // Hide the consent box
    };

    return (
        <>
            {isVisible && (
                <div className='bg-white mx-4 md:mx-0 md:w-[607px] py-5 px-6 rounded-[18px] border fixed z-50 bottom-[140px] md:bottom-4 md:left-1/2 md:transform md:-translate-x-1/2 shadow-2xl md:flex items-center'>
                    <div className='flex items-center'>
                        <div className='text-[40px] leading-[40px] -ml-2'>🍪</div>
                        <div className='text-[14px]'>
                            By clicking Ok and using this site you accept our <a className='text-blue-500' href="https://cricketgully.com/p/privacy-policy" target='_blank'>Privacy Policy </a>
                            of CricketGully and you also accept <a className='text-blue-500' href='https://cricketgully.com/p/terms-of-use' target='_blank'>Terms of Service</a>
                        </div>
                    </div>
                    <div className=''>
                        <button
                            className='w-full md:w-auto rounded-[12px] bg-[#3A32D1] font-medium py-[10px] px-6 text-white md:ml-8 mt-3 md:mt-0'
                            onClick={handleConsent}
                        >
                            OK
                        </button>
                    </div>
                </div>
            )}
        </>
    );
};

export default CookieConsent;
