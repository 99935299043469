import React, { useState } from 'react'
import { IoChevronUpOutline } from 'react-icons/io5';
import { formatBlockTime } from '../../../../helpers/general';

export default function KeyEvents({ data }) {

    const [isOpen, setIsOPen] = useState(true);

    const toggleTableOfContents = () => {
        setIsOPen(!isOpen);
    };

    const onContentClick = (id) => {
        scrollSmooth(id, -170);
    };

    return (
        <div className="md:mx-10 md:mt-10 mt-4 rounded-[14px] border-[#DAE6F8] border overflow-hidden">
            <div
                className={`bg-[#E8F1FF] ${isOpen ? 'rounded-t-[14px]' : 'rounded-[14px]'} md:text-2xl text-base leading-6 p-4 font-bold`}
                onClick={toggleTableOfContents}
            >
                <div className="flex items-center gap-2 justify-between cursor-pointer font-bold">
                    Key Events
                    <IoChevronUpOutline
                        className={`text-[#3A32D1] transition duration-200 ${isOpen ? '' : 'rotate-180'}`}
                    />
                </div>
            </div>
            <div
                className={`content-container ${isOpen ? 'content-container-open' : 'content-container-closed'} md:grid grid-cols-1 gap-4 transition-max-height`}
            >
                {isOpen && (
                    <div className={`md:p-[20px] md:grid grid-cols-1 gap-4 p-2 transition duration-300 ease-in-out key-event-scroll-bar ${isOpen ? 'max-h-[270px] md:max-h-[350px] overflow-y-auto bg-[#F9FBFF]' : 'max-h-0 overflow-hidden'}`}>
                        <div>
                            {data?.map((block) => (
                                <div className='font-[Poppins] mb-4 md:mb-5 cursor-pointer' key={block.id} onClick={() => onContentClick(block.id)}>
                                    <h6 className='text-sm md:text-base font-bold text-[#080A3C]'>{formatBlockTime(block.blockTime)}</h6>
                                    <p className='text-xs md:text-sm font-medium text-[#000000]'>{block.title}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
