import { useEffect, useCallback } from "react";

export const useLoadSocialMediaOnce = () => {
    // Load scripts once on mount
    useEffect(() => {
        // Twitter script
        if (!document.querySelector('script[src*="platform.twitter.com"]')) {
            const twitterScript = document.createElement('script');
            twitterScript.charset = 'utf-8';
            twitterScript.src = 'https://platform.twitter.com/widgets.js';
            twitterScript.async = true;
            document.body.appendChild(twitterScript);
        }

        // Instagram script
        if (!document.querySelector('script[src*="instagram.com/embed.js"]')) {
            const instaScript = document.createElement('script');
            instaScript.src = '//www.instagram.com/embed.js';
            instaScript.async = true;
            instaScript.onload = () => {
                if (window.instgrm) {
                    window.instgrm.Embeds.process();
                }
            };
            document.body.appendChild(instaScript);
        }

        // No cleanup since scripts stay in DOM
    }, []); // Empty dependency array = runs once on mount

    // Return a method to manually re-process embeds
    const reprocessEmbeds = useCallback(() => {
        if (window.twttr?.widgets) {
            window.twttr.widgets.load();
        }
        if (window.instgrm) {
            window.instgrm.Embeds.process();
        }
    }, []); // Empty dependency array = stable function reference

    return { reprocessEmbeds };
};