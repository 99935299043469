import React, { useState, useRef, useEffect, useMemo, useContext } from "react";
import { useAxiosSWR } from "root/axiosInstance";
import AppContext, { URL_TYPES } from "src/context";
import { getDateFormate, getScheduleMockData, processOnData } from "./helper";
import { doScrollToTop, getSanitizedArray, scrollSmooth } from "../../../../helpers/general";
import SEOMeta from "../../Home/common/SEOMeta";
import CalendarBar, { FilterButtons } from "./common";
import { NoMatch } from "./components/NoMatch";
import { RecentMatchformatDates } from "../../../../helpers/dates";
import { MatchBox } from "../../../common/MatchBoxes/MatchBox";
import { useLiveCalenderFilterButtonTopPositionOnScroll } from "../../../../hooks/useShowHideOnScroll";

const initialState = {
  gameFormats: [],
  datesList: [],
  matchesById: {},
  matchListByDates: {},
  gameFormatsByDate: {},
};

const Upcoming = () => {
  const [isSelect, setIsSelect] = useState("All");
  const [state, setState] = useState(initialState);
  const [dateRange, setDateRange] = useState({});
  const dateRef = useRef();
  const appContext = useContext(AppContext);
  useEffect(() => {
      const urlInfoObj = {
          type: URL_TYPES.SCHEDULE_UPCOMING,
          path: "/schedule/upcoming",
          reqState: null,
      };
      appContext.updateUrlInfo(urlInfoObj);
  }, []);

  const getUpcomingDateRange = (date, type = "next") => {
    dateRange.fromDate && scrollSmooth("upcoming_matches_container")
    let formDate = new Date(date);
    const toDate = new Date(date);
    if (type === "previous") {
      if (new Date().getDate() + 10 > formDate.getDate() &&
        new Date().getMonth() === formDate.getMonth() &&
        new Date().getFullYear() === formDate.getFullYear()) {
        formDate = new Date();
        toDate.setDate(formDate.getDate() + 10);
      } else {
        formDate.setDate(formDate.getDate() - 10);
      }
    } else if (type === "next") {
      toDate.setDate(toDate.getDate() + 10);
    }
    const formtedFormDate = getDateFormate(formDate);
    const formtedToDate = getDateFormate(toDate);
    dateRef.current = formtedFormDate;
    setTimeout(() => {
      setDateRange({ fromDate: formtedFormDate, toDate: formtedToDate });
    }, 500);
  };
  useEffect(() => {
    getUpcomingDateRange(new Date());
  }, []);
  const { gameFormats, matchesById, matchListByDates, datesList } = state;
  const {
    data: upcomingData,
    error: isError,
    isLoading,
    mutate,
  } = useAxiosSWR(
    dateRange.toDate && dateRange.fromDate
      ? `/v2/matches/schedule/upcoming/${dateRange.fromDate}/${dateRange.toDate}`
      : undefined
  );

  const mockData = useMemo(() => {
    return getScheduleMockData();
  }, []);

  const upcomingMatchData = isLoading ? mockData : upcomingData;

  const containerRef = useRef(null);
  const { fromDate, toDate } = dateRange;
  useEffect(() => {
    if (upcomingMatchData) {
      setState(processOnData({ data: upcomingMatchData, fromDate, toDate, schduleType: "upcoming" }));
    }
  }, [upcomingMatchData, fromDate, toDate]);

  const handleMatchTypeChange = (type) => {
    if (!isLoading) {
      setIsSelect(type);
    }
  };

  useEffect(() => {
    if (gameFormats.includes(isSelect) === false) {
      setIsSelect("All")
    }
  }, [isSelect, gameFormats])

  useEffect(() => {
    if (dateRef.current) {
          scrollSmooth(`matches_on_${dateRef.current}`, -190);
    } else {
      doScrollToTop();
    }
  }, [isSelect])

  const classname = upcomingMatchData?.isShimmer ? "bg-gray-300 loading text-gray-300" : "";

  const onDateClick = (dateStr) => {
    scrollSmooth(`matches_on_${dateStr}`, -190)
    dateRef.current = dateStr;
  }

  const calenderBarRef = useRef(null);
  const filterBtnRef = useRef(null);

  useLiveCalenderFilterButtonTopPositionOnScroll(calenderBarRef, filterBtnRef);

  return (
    <div className="">
      <div ref={calenderBarRef} data-testid="container_calendar_bar" className="sticky top-[48px] z-40">
        <CalendarBar
          getDateRange={getUpcomingDateRange}
          dateRange={dateRange}
          pageName="upcoming"
          isLoading={isLoading}
          onDateClick={onDateClick}
        />
      </div>
      <div ref={containerRef} id="upcoming_matches_container" className="md:bg-white rounded-xl md:border md:p-4 px-[6px] mt-2 md:mt-0 md:px-4">
        <SEOMeta slug="schedule-upcoming" />
        <h1 className="hidden">Schedule Upcoming</h1>
        {gameFormats.length > 0 && !isError && (
          <div ref={filterBtnRef} className="sticky top-[110px] md:static md:bg-transparent bg-[#eef0f3] z-30">
            <FilterButtons
              gameFormats={gameFormats}
              isSelect={isSelect}
              onMatchTypeChange={handleMatchTypeChange}
            />
          </div>
        )}
        {isError && (
          <div className="text-red-500 mb-4 p-4"></div>
        )}
        <div data-testid="full_container" className={`${isError ? 'hidden' : ''}`}>
          <div>
            {datesList.map((date) => {
              const allMatchIdsOfTheDay = getSanitizedArray(matchListByDates[date]);
              const matchedIdsToBeDisplayed = isSelect === "All" ? allMatchIdsOfTheDay : allMatchIdsOfTheDay.filter(matchId => matchesById[matchId]?.format === isSelect);
              return (
                <div key={date} id={`matches_on_${date}`}>
                  <div className={`${classname} my-4 mt-5 md:min-h-[18px] md:min-w-[180px] min-w-[50px] md:text-[24px] leading-[28px] text-[#000000] font-bold flex items-center`}>
                    {RecentMatchformatDates(date)}
                    <span className="custom-border-right ml-6"></span>
                  </div>

                  {matchedIdsToBeDisplayed?.length > 0 ? (
                    <div className={`col-span-4 md:border border-[#E3E3E4] rounded-xl ${matchListByDates[date]?.length > 0 ? '' : 'hidden'}`}>
                      <SEOMeta slug="schedule-upcoming" />
                      {matchedIdsToBeDisplayed.map((matchId, i) => (
                        <div key={i} className={`${i === matchedIdsToBeDisplayed.length - 1 ? '' : 'md:border-b'} bg-white md:bg-transparent ${i === 0 ? "" : "md:mt-0 mt-3"} md:rounded-none rounded-xl`}>
                          <MatchBox
                            doAutoScroll={false}
                            customClass={classname}
                            match={matchesById[matchId]}
                          />
                        </div>
                      ))}
                    </div>
                  ) : !isLoading && !isError && (
                    <div className="text-center flex justify-center border rounded-xl bg-white">
                      <NoMatch text="No Upcoming matches on this date" gameAndDate={`${date}__${isSelect}__Upcoming`} />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Upcoming;