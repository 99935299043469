import React, { useEffect, useMemo } from 'react'
import { useAxiosSWR } from '../../../../../axiosInstance';
import LiveAnimatedRedDot from '../../../common/LiveAnimatedRedDot';
import { getSanitizedWinPer } from '../../../../helpers/general';
import { CompletedMatch, LiveMatch, ScheduledMatch } from '../../Schedule/MatchInstance/component';
import { getRightOrder } from '../../Home/common/liveMatchesCarousel/helper';

export default function MatchStatus({ matchId }) {

    const { data, isError, isLoading, mutate } = useAxiosSWR(matchId ? `v1/matches/${matchId}` : null);
    const LiveMatchStatus = data && data?.match?.status === "Live";
    useEffect(() => {
        let timerId;
        if (LiveMatchStatus) {
            timerId = setInterval(() => {
                mutate();
            }, 15000);
        }

        return () => {
            clearInterval(timerId);
        }
    }, [LiveMatchStatus])

    const matchData = data?.match;
    const liveScore = data?.info?.live_score;
    const teamA = matchData?.teama;
    const teamB = matchData?.teamb;
    const orderedTeams = getRightOrder(matchData?.teama, matchData?.teamb);
    const renderLiveMatchIcon = (index) => {
        if (index + 1 == matchData?.live_inning_number) {
            return (
                // <img src={liveIcon} className=" h-2 mt-2 mr-1" />
                <div className="mt-[10px] mr-1">
                    <LiveAnimatedRedDot height="5px" width="5px" />
                </div>
            );
        } else {
            return <span className="ml-2">&nbsp;</span>;
        }
    };
    const renderCurrentRRLine = () => {
        if (!matchData) {
            return null;
        }
    
        if (matchData?.status === "Completed") {
            return null;
        }
        const arr = [];
        liveScore?.runrate && liveScore?.runrate > 0
            ? arr.push(`Current RR: ${liveScore?.runrate}`)
            : "";
        liveScore?.required_runrate && liveScore?.required_runrate > 0
            ? arr.push(`Required RR: ${liveScore?.required_runrate}`)
            : "";
        return (
            <div className="text-[#808080] text-[10px] md:text-[14px]">
                {arr.join(" • ")}
            </div>
        );
    };
    const chartData = useMemo(() => {
        if (!teamA || !teamB) {
            return []
        }
        return [teamA, teamB].map(t => ({
            logoUrl: t.logo,
            name: (t.short_name + "").slice(0, 3),
            percentage: t.win_per ? getSanitizedWinPer(t.win_per) : null,
        }))
    }, [teamA, teamB]);

    const renderMatchStatus = () => {
        if (!matchData) {
            return null;
        }
        switch (matchData?.status) {
            case "Live":
                return <LiveMatch chartData={chartData} matchData={matchData} tieChance={matchData?.tie_per} orderedTeams={orderedTeams} renderCurrentRRLine={renderCurrentRRLine} renderLiveMatchIcon={renderLiveMatchIcon} liveScore={liveScore} />;
            case "Completed":
                return <CompletedMatch matchData={matchData} orderedTeams={orderedTeams} liveScore={liveScore} />;
            case "Scheduled":
            default:
                return <ScheduledMatch matchData={matchData} orderedTeams={orderedTeams} />;
        }
    };

    if (isLoading) {
        return <div className="min-h-screen">Loading Data</div>;
    }
    if (isError) {
        return (
            <div className="flex flex-col items-center justify-center text-center mt-4">
                <h2>Something Went Wrong...</h2>
                <button onClick={mutate} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4">Try Again</button>
            </div>
        )
    }

    return (
        <div className={`md:p-5 md:rounded-2xl md:border md:bg-white md:mx-10 bg-[#312AB7] py-5`}>
            <div className="mx-5 md:mx-0">
                {renderMatchStatus()}
            </div>
        </div>
    )
}
