import React, { useRef, useState, useEffect, useContext, useMemo } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { useAxiosSWR } from "root/axiosInstance";
import { formattedDate } from "../../../../helpers/dates";
import { useHorizontalScroll } from "../../../../hooks/useHorizontalScroll";
import { getId } from "./helper";
import AppContext, { URL_TYPES } from "src/context";

const SeriesFixtures = React.lazy(()=> import("./SeriesFixtures"));
const SeriesNews = React.lazy(()=> import( "./SeriesNews"));
const SeriesPointTable = React.lazy(()=> import( "./SeriesPointTable"));
const SeriesVideos = React.lazy(()=> import( "./SeriesVideos"));
const SeriesTeamsV2 = React.lazy(()=> import( "./SeriesTeamsV2"));

const DisplayMap = {
  "schedule": "Schedule and Results",
  pointtable: "Points Table",
  teams: "Teams and Squad",
  news: "News",
  videos: "Videos"
};

// const PagesArray = ["News", "Schedule", "Videos", "PointTable", "Teams"];
// const PagesArrayLower = PagesArray.map((p) => p.toLowerCase());

const schedule = "schedule";
const PointTable = "pointtable";
const News = "news";
const Videos = "videos";
const Teams = "teams";
const SelectSeriesPages = () => {
  const { id: slug, type } = useParams();
  const containerRef = useRef();
  const navigated = useNavigate();
  const [seriesPageType, setSeriesPageType] = useState(type);
  useHorizontalScroll(containerRef, seriesPageType);
  const handleSeriesTypeChange = (type) => {
    setSeriesPageType(type);
  };
  const seriesId = getId(slug)
  const reqObj = useAxiosSWR(`/v1/series/${seriesId}/pointtable`);
  const { data, error, isLoading } = reqObj;
  const PagesArray = useMemo(() => {
    const basePages = ["news", "schedule", "videos", "pointtable", "teams"];

    if (!data?.series?.show_pointtable) {
      return basePages.filter(page => page !== "pointtable");
    }

    return basePages;
  }, [data]);
  useEffect(() => {
    const validType = PagesArray.includes(type?.toLowerCase());
    if (type === undefined || !validType) {
      navigated(`/series/${slug}/${schedule}`);
      return;
    }
    setSeriesPageType(type);
  }, [type]);
  const seriesData = data?.series;
  const startDate = seriesData?.date_start ? formattedDate(seriesData?.date_start, false) : "";
  const endDate = seriesData?.date_end ? formattedDate(seriesData?.date_end, false) : "";
  const seriesTitle = seriesData?.title;
  const appContext = useContext(AppContext);
  const { pathname: pathName } = useLocation();

  useEffect(() => {
      const urlInfoObj = {
          type: URL_TYPES.SERIES_INSTANCE,
          path: pathName,
          reqState: {
              data: {
                title: seriesTitle,
              },
          }
      };
      appContext.updateUrlInfo(urlInfoObj);
  }, [pathName, seriesTitle]);

  const renderComponent = () => {
    switch (type) {
      case schedule:
        return <React.Suspense><SeriesFixtures /></React.Suspense>;
      case PointTable:
        return <React.Suspense><SeriesPointTable /></React.Suspense>;
      case News:
        return <React.Suspense><SeriesNews /></React.Suspense>;
      case Videos:
        return <React.Suspense><SeriesVideos /></React.Suspense>;
      case Teams:
        return <React.Suspense><SeriesTeamsV2 /></React.Suspense>;
      default:
        return null; // Render nothing if type doesn't match
    }
  };

  if (isLoading) {
    return <div></div>
  }
  if (error) {
    return <div></div>
  }
  return (
    <div className="">
      <div>
        {seriesData ? (
          <div className="mb-4">
            <div className="border border-[#312AB7] md:bg-[#FFFFFF] bg-[#312AB7] md:rounded-[20px] md:pl-[30px] md:p-6 md:mt-4 -mt-1">
              <div>
                <div className="md:block hidden">
                <div className={`md:min-h-[18px] md:min-w-[180px] flex items-center min-w-[50px]`}>
                  <span className="custom-border-left mr-3 md:mr-6"></span>
                  <h1 className='text-[16px] md:text-2xl'>{seriesData.title}</h1>
                  <span className="custom-border-right ml-3 md:ml-6"></span>
                </div>
                <p className={`text-center md:text-sm text-xs`}>
                  {seriesData?.total_matches}{" "}
                  {seriesData?.game_format?.toUpperCase()} •{" "}
                  {startDate} - {endDate}
                </p>
                </div>
                <div className="flex md:px-2 mx-auto justify-center mt-2">
                  <div ref={containerRef} className="flex text-sm md:gap-4 overflow-x-auto w-max hideScrollbar md:rounded-[14px] cursor-pointer">
                    {PagesArray.map((type) => (
                      <Link
                        to={`/series/${slug}/${type.toLowerCase()}`}
                        key={type}
                        data-testid={type.toLowerCase()}
                      >
                        <div
                          className={`font-bold px-5 md:py-2 py-3 md:text-base text-nowrap text-xs ${seriesPageType === type.toLowerCase()
                            ? "text-[#FFFFFF] md:bg-blue-700 bg-[#312AB7] md:rounded-[14px] p-1 md:border-none border-b-2 border-[#FFFFFF]"
                            : "md:border-2 md:border-[#EEEEEF] md:text-[#000000] text-[#FFFFFF] md:rounded-[14px] opacity-60"}`}
                          onClick={() => handleSeriesTypeChange(type.toLowerCase())}
                        >
                          {DisplayMap[type] || type}
                        </div>
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <div className="">
        {!isLoading && !error && data && renderComponent()}
      </div>
    </div>
  );
};

export default SelectSeriesPages;

function getMeUrl(pageType, id) {
  const lower = typeof pageType === "string" ? pageType.toLowerCase() : "";
  if (PagesArray.includes(lower)) {
    return `/v1/series/${id}/${lower === "schedule" ? "fixtures" : lower}`;
  }
  return null;
}
