import { useEffect, useState } from "react";

export const useHackNx = (config = {}) => {
    const interval = config?.interval || 2000;
    const preFixStr = config?.preFixStr || '';

    const [nx, setNx] = useState(1);
    useEffect(() => {
        console.log(`${preFixStr}   |  current nx is -> ${nx}`)
        let timerId;
        if (nx > 0) {
            timerId = setInterval(() => {
                setNx(p => {
                    if (p <= 0) {
                        return p;
                    } else {
                        console.log(`${preFixStr}   | new nx ${p + 1}`);
                        return p + 1;
                    }
                });
            }, interval);
        }

        return () => {
            clearInterval(timerId);
        }
    }, [nx])

    return { setNx };   
}