import React from 'react';
import { useState, useEffect, useRef, lazy, Suspense } from 'react';
import { getAssetUrl } from '../../../helpers/general';
import { autoChangeShortVideoAfterComplete } from '../../../constants';
import { AnalyticsCategory, TrackGoogleAnalyticsEvent } from '../../../helpers/analytics';

const next = getAssetUrl("next-svg.svg");
const previous = getAssetUrl("prev-svg.svg");
const downArrow = getAssetUrl("down-arrow.svg");
const readMoreArrow = getAssetUrl("read-more-double-arrow.svg");
const playIcon = getAssetUrl("play_circle.svg");
const pauseIcon = getAssetUrl("pause_circle.svg");
const muteIcon = getAssetUrl("volume_off.svg");
const unMuteIcon = getAssetUrl("volume_on.svg");
const shareIcon = getAssetUrl("share_icon.svg");
const backIcon = getAssetUrl("back-arrow.svg");

const VIDEO_DURATION = 30000;
const IMAGE_DURATION = 10000;

const ReactPlayer = lazy(() => import('react-player'));

const ShowShorts = ({ activeId, storyData, setActiveId, setIsOpen }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [currentMediaIndex, setCurrentMediaIndex] = useState(0);
    const [isMuted, setIsMuted] = useState(false);
    const [isPaused, setIsPaused] = useState(false);
    const videoRefs = useRef([]);
    const [progress, setProgress] = useState([]);
    const mediaTimers = useRef([]);
    const [showPopup, setShowPopup] = useState(false);

    const data = storyData?.map(group => ({
        id: group.id,
        media: group.stories.map(story => ({
            id: story.id,
            title: story.title,
            text: story.body,
            type: story.type,
            src: story.media[0].url,
            imgSrc: story.media[0].thumb,
            ctaURL: story.ctaURL,
            source: story.source,
        })),
        info: group.info
    })) || [];

    const currentMedia = data?.[activeIndex];

    useEffect(() => {
        document.body.style.overflow = "hidden";
        return () => (document.body.style.overflow = "auto");
    }, []);

    useEffect(() => {
        if (data.length > 0 && activeId) {
            const index = data.findIndex(item => item.id === parseInt(activeId));
            if (index !== -1) {
                setActiveIndex(index);
            } else {
                setIsOpen(false);
                setActiveId(null);
            }
        }
    }, [data, activeId, setActiveId, setIsOpen]);

    const resetProgress = () => {
        return data.map((item) => ({
            videoProgress: Array(item.media.length).fill(0),
            imageProgress: Array(item.media.length).fill(0),
        }));
    };

    const closeShorts = () => {
        setProgress(resetProgress());
        setCurrentMediaIndex(0);
        setIsMuted(false);
        setIsPaused(false);
        mediaTimers.current.forEach((timer) => clearTimeout(timer));
        mediaTimers.current = [];
        document.body.style.overflow = 'auto';
        setIsOpen(false);
        setActiveId(null);
    };

    const handleEscapeKeyDown = (e) => {
        if (e.key === 'Escape') {
            closeShorts();
        }
    }

    useEffect(() => {
        window.addEventListener('keydown', handleEscapeKeyDown);
        return () => window.removeEventListener('keydown', handleEscapeKeyDown);
    }, [])

    const initialProgressSet = useRef(false);

    useEffect(() => {
        if (data && data.length > 0 && !initialProgressSet.current) {
            setProgress(resetProgress());
            initialProgressSet.current = true;
        }
    }, [data]);

    const handleNextStory = () => {
        if (data[activeIndex]?.media?.length > currentMediaIndex + 1) {
            setProgress((prev) => {
                const updated = [...prev];
                if (updated[activeIndex]) {
                    if (data[activeIndex].media[currentMediaIndex]?.type === "image") {
                        updated[activeIndex].imageProgress[currentMediaIndex] = 100;
                    } else if (data[activeIndex].media[currentMediaIndex]?.type === "video") {
                        updated[activeIndex].videoProgress[currentMediaIndex] = 100;
                    }
                }
                return updated;
            });

            setCurrentMediaIndex((prev) => prev + 1);
        } else if (activeIndex < data.length - 1) {
            const newIndex = activeIndex + 1;
            setActiveIndex(newIndex);
            setCurrentMediaIndex(0);
            setActiveId(data[newIndex].id.toString());

            setProgress((prev) => {
                const updated = [...prev];
                if (updated[newIndex]) {
                    if (data[newIndex]?.media[0]?.type === "image") {
                        updated[newIndex].imageProgress[0] = 0;
                    } else if (data[newIndex]?.media[0]?.type === "video") {
                        updated[newIndex].videoProgress[0] = 0;
                    }
                }
                return updated;
            });
        } else {
            closeShorts();
        }
        // Reset video time to zero
        if (videoRefs.current[activeIndex]?.[currentMediaIndex]?.seekTo) {
            videoRefs.current[activeIndex][currentMediaIndex].seekTo(0);
        }
    };

    const handlePreviousStory = () => {
        setProgress((prev) => {
            const updated = [...prev];
            if (updated[activeIndex]) {
                if (data[activeIndex].media[currentMediaIndex]?.type === "image") {
                    updated[activeIndex].imageProgress[currentMediaIndex] = 0;
                } else if (data[activeIndex].media[currentMediaIndex]?.type === "video") {
                    updated[activeIndex].videoProgress[currentMediaIndex] = 0;
                }
            }
            return updated;
        });

        if (currentMediaIndex > 0) {
            setProgress((prev) => {
                const updated = [...prev];
                if (updated[activeIndex] && updated[activeIndex].imageProgress) {
                    updated[activeIndex].imageProgress[currentMediaIndex - 1] = 0;
                }
                return updated;
            });
            setCurrentMediaIndex((prev) => prev - 1);
        } else if (activeIndex > 0) {
            setProgress((prev) => {
                const updated = [...prev];
                const previousIndex = activeIndex - 1;
                updated[previousIndex] = {
                    videoProgress: Array(data[previousIndex].media.length).fill(0),
                    imageProgress: Array(data[previousIndex].media.length).fill(0),
                };
                return updated;
            });

            const newIndex = activeIndex - 1;
            setActiveIndex(newIndex);
            setCurrentMediaIndex(0);
            setActiveId(data[newIndex].id.toString());
        }
        // Reset video time to zero
        if (videoRefs.current[activeIndex]?.[currentMediaIndex]?.seekTo) {
            videoRefs.current[activeIndex][currentMediaIndex].seekTo(0);
        }
    };

    const handleProgress = ({ played }) => {
        const progressPercentage = played * 100;

        setProgress((prev) => {
            const updated = [...prev];
            if (updated[activeIndex] && updated[activeIndex].videoProgress) {
                updated[activeIndex].videoProgress[currentMediaIndex] = progressPercentage;
            }
            return updated;
        });
    };

    const handleImageProgress = () => {
        const timerInterval = 100;
        const progressIncrement = (100 / (IMAGE_DURATION / timerInterval));

        setProgress((prev) => {
            const updated = [...prev];
            if (updated[activeIndex] && updated[activeIndex].imageProgress) {
                const currentProgress = updated[activeIndex].imageProgress[currentMediaIndex] || 0;
                const updatedProgress = Math.min(currentProgress + progressIncrement, 100);

                updated[activeIndex].imageProgress[currentMediaIndex] = updatedProgress;
                if (updatedProgress >= 100) {
                    autoChangeShortVideoAfterComplete && handleNextStory();
                }
            }
            return updated;
        });
    };

    useEffect(() => {
        mediaTimers.current.forEach((timer) => clearTimeout(timer));
        mediaTimers.current = [];

        const currentMedia = data[activeIndex]?.media[currentMediaIndex];

        if (currentMedia?.type === 'image') {
            if (!isPaused) {
                const progressTimer = setInterval(handleImageProgress, 100);
                mediaTimers.current.push(progressTimer);

                const endTimer = setTimeout(() => {
                    clearInterval(progressTimer);
                    autoChangeShortVideoAfterComplete && handleNextStory();
                }, IMAGE_DURATION);
                mediaTimers.current.push(endTimer);
            }
        } else if (currentMedia?.type === 'video') {
            if (!isPaused) {
                const video = videoRefs.current[activeIndex]?.[currentMediaIndex];
                const videoDuration = video?.duration || VIDEO_DURATION / 1000;

                if (video) {
                    video.onended = () => {
                        autoChangeShortVideoAfterComplete && handleNextStory();
                    };
                }

                const videoTimer = setTimeout(() => {
                    autoChangeShortVideoAfterComplete && handleNextStory();
                }, Math.min(videoDuration * 1000, VIDEO_DURATION));
                mediaTimers.current.push(videoTimer);
            }
        }

        return () => {
            mediaTimers.current.forEach((timer) => clearTimeout(timer));
        };
    }, [activeIndex, currentMediaIndex, isPaused, data]);

    useEffect(() => {
        videoRefs.current.forEach((videoArr, index) => {
            videoArr.forEach((video, mediaIndex) => {
                if (video && video.getInternalPlayer) {
                    const player = video.getInternalPlayer();
                    if (index === activeIndex && mediaIndex === currentMediaIndex) {
                        player.muted = isMuted;
                        if (!isPaused) {
                            player.play();
                        } else {
                            player.pause();
                        }
                    } else {
                        player.pause();
                    }
                }
            });
        });
    }, [activeIndex, currentMediaIndex, isMuted, isPaused]);

    const toggleMute = () => {
        setIsMuted(!isMuted);
    };

    const togglePause = () => {
        setIsPaused(!isPaused);
    };

    const handleShareClick = (e) => {
        e.preventDefault();
        try {
            if (navigator.share) {
                const slug = currentMedia?.info?.slug ? `${currentMedia?.info?.slug}-${currentMedia.id}` : currentMedia.id
                navigator.share({
                    title: currentMedia?.media?.[0].title || "",
                    text: currentMedia?.media?.[0].text || "",
                    url: `${window.location.origin}/web-stories/${slug}`,
                });
                return;
            }
        } catch (error) {
            console.log('Sharing failed.. now sharing manually... , Error is ', error);
        }
        try {
            navigator.clipboard.writeText(window.location.href);
        } catch (error) {
        }
        setShowPopup(!showPopup);
        TrackGoogleAnalyticsEvent(AnalyticsCategory.Shorts, 'stories_share', window.location.pathname)
    };

    const handleClosePopup = () => {
        setShowPopup(false);
    };

    return (
        <>
            {showPopup && <div className="overlay z-40" onClick={handleClosePopup} />}
            {showPopup && (
                <div className="modal-content fixed bg-white shadow-md md:py-4 md:px-[30px] p-4 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-40 rounded-2xl">
                    <SocialLinksComponent
                        socialLinks={socialLinksV2}
                        className="container"
                        type="share"
                        textInputValue={window.location.href}
                        closeIcon={<HiMiniXMark className="md:w-6 md:h-6 w-5 h-5 border border-[#E3E3E4] text-gray-400 rounded-full cursor-pointer" onClick={handleClosePopup} />} />
                </div>
            )}
            <div className="show-shorts-sec fixed bottom-0 left-0 z-[10001] w-full">
                <div className="relative shorts-container h-full">

                    {/* largeDevice navigation buttons */}
                    <button
                        className={`absolute top-1/2 left-[31%] z-10 hidden ${activeIndex === 0 && currentMediaIndex === 0 ? 'hidden' : 'lg:block'}`}
                        onClick={handlePreviousStory}
                        disabled={activeIndex === 0 && currentMediaIndex === 0}
                    >
                        <img src={previous} alt="previous" width="65" />
                    </button>
                    <button
                        className={`absolute top-1/2 right-[31%] z-10 hidden ${activeIndex === data.length - 1 && currentMediaIndex === data[activeIndex].media.length - 1 ? 'hidden' : 'lg:block'}`}
                        onClick={handleNextStory}
                        disabled={activeIndex === data.length - 1 && currentMediaIndex === data[activeIndex].media.length - 1}
                    >
                        <img src={next} alt="next" width="65" />
                    </button>

                    {/* small Device navigation */}
                    <div
                        className='flex lg:hidden justify-start items-center absolute top-1/2 -translate-y-1/2 left-0 h-1/2 w-1/3 z-10 opacity-0 transition-opacity duration-300'
                        onClick={handlePreviousStory}
                        style={{ pointerEvents: activeIndex === 0 && currentMediaIndex === 0 ? 'none' : 'auto' }}
                    >
                        <img src={previous} alt="previous-icon" width={70} />
                    </div>
                    <div
                        className='flex lg:hidden justify-end items-center absolute top-1/2 -translate-y-1/2 right-0 h-1/2 w-1/3 z-10 opacity-0 transition-opacity duration-300'
                        onClick={handleNextStory}
                        style={{ pointerEvents: activeIndex === data.length - 1 && currentMediaIndex === data[activeIndex].media.length - 1 ? 'none' : 'auto' }}
                    >
                        <img src={next} alt="next-icon" width={70} />
                    </div>

                    <div className="flex items-center justify-center w-full h-full overflow-hidden">
                        {data.map((item, index) => {
                            const offset = index - activeIndex;
                            const isActive = activeIndex === index;
                            const isPrevious = index === activeIndex - 1;
                            const isNext = index === activeIndex + 1;

                            let transformValue = 0;

                            if (isActive) {
                                transformValue = 0;
                            } else if (isPrevious || isNext) {
                                transformValue = offset * 450;
                            } else {
                                transformValue = offset > 0 || offset < 0 ? offset * 350 : offset * 200;
                            }

                            return (
                                <div
                                    key={item.id}
                                    className={`absolute transition-opacity duration-300 ${isActive ? "opacity-100" : "opacity-40"}`}
                                    style={{
                                        transform: `translateX(${transformValue}px)`,
                                    }}
                                >
                                    <div className="relative md:rounded-[28px] overflow-hidden">
                                        <div
                                            className={`shorts-box flex md:block relative ${isActive
                                                ? "w-screen md:w-[488px] shorts-box-height md:rounded-[28px]"
                                                : isNext || isPrevious ? "w-[191px] h-[335px] rounded-[19px]" : "hidden"
                                                }`}
                                        >
                                            {item.media.map((media, mediaIndex) => {
                                                const isMediaActive = isActive && currentMediaIndex === mediaIndex;
                                                return (
                                                    <React.Fragment key={mediaIndex}>
                                                        {media.type === "video" ? (
                                                            <Suspense fallback={<div>Loading...</div>}>
                                                                <ReactPlayer
                                                                    key={`${mediaIndex}-${activeIndex}`}
                                                                    ref={(el) => {
                                                                        if (!videoRefs.current[index]) {
                                                                            videoRefs.current[index] = [];
                                                                        }
                                                                        videoRefs.current[index][mediaIndex] = el;
                                                                    }}
                                                                    url={media.src}
                                                                    playing={isMediaActive && !isPaused}
                                                                    muted={isMuted && isMediaActive}
                                                                    onProgress={handleProgress}
                                                                    onEnded={handleNextStory}
                                                                    width="100%"
                                                                    height="auto"
                                                                    style={{ 'display': `${isMediaActive ? "flex" : isPrevious || isNext ? "flex" : "none"}` }}
                                                                    controls={false}
                                                                    loop={false}
                                                                    playbackRate={1}
                                                                    volume={1}
                                                                    playsinline
                                                                />
                                                            </Suspense>
                                                        ) : (
                                                            <img
                                                                key={`${mediaIndex}-${activeIndex}`}
                                                                src={media.src}
                                                                alt={media.title}
                                                                className={`w-full h-full ${isMediaActive ? "short-active-image" : isPrevious || isNext ? "" : "hidden"}`}
                                                            />
                                                        )}
                                                        <div className={`${isMediaActive ? 'block' : 'hidden'} shorts-cont absolute bottom-0 w-full z-10 p-5 pb-6`}>
                                                            {media.title && isMediaActive && (
                                                                <h2 className={`block font-semibold leading-tight text-white ${isActive ? 'title-animation text-[20px] md:text-[26px] mb-3' : 'text-[19px]'}`}>
                                                                    {media.title}
                                                                </h2>
                                                            )}
                                                            {media.title && media.text && isMediaActive ?
                                                                <span className={`shorts-text-line ${isActive ? 'block' : 'hidden'}`} ></span>
                                                                : ''
                                                            }
                                                            {media.text && isMediaActive && (
                                                                <p className={`text-white mt-2 text-base md:text-[20px] font-normal leading-tight ${media.text.length > 150 ? 'md:text-[17px]' : ''}`}>
                                                                    {media.text}
                                                                </p>
                                                            )}
                                                            {isMediaActive && media.ctaURL && (
                                                                <div className={`relative shorts-read-more-btn z-10 ${isActive ? 'block' : 'hidden'}`}>
                                                                    {media.ctaURL ?
                                                                        <>
                                                                            <img src={readMoreArrow} alt="read-more-arrow" className='mx-auto mb-2' />
                                                                            <div className='flex justify-center'>
                                                                                <a href={media.ctaURL} target='_blank' className={`flex items-center gap-2 bg-white text-[#3A32D1] text-[16px] font-bold shadow-sm rounded-lg h-9 px-2 mx-auto`}>
                                                                                    <img src={media?.src} alt="shorts-thumb" className='h-[22px] w-[22px] rounded-[4px] object-cover object-top' />
                                                                                    Read More
                                                                                    <img src={downArrow} alt="down-arrow" />
                                                                                </a>
                                                                            </div>
                                                                        </> : null
                                                                    }
                                                                </div>
                                                            )}
                                                        </div>
                                                        {/* MediaSource */}
                                                        {isMediaActive && media?.source ?
                                                            <div className="absolute z-10 bottom-2 right-3 flex items-center shadow-lg bg-[#00000033] backdrop-blur-lg rounded-xl px-2 gap-1 text-white">
                                                                {media?.source ? (
                                                                    <>
                                                                        <span className="text-[10px] rounded">
                                                                            {media.type === 'video' ? 'Video Credit : ' : 'Image Credit : '} {media?.source}
                                                                        </span>
                                                                    </>
                                                                ) : null}
                                                            </div>
                                                            : null
                                                        }
                                                    </React.Fragment>
                                                );
                                            })}

                                            {/* mediaButtons */}
                                            <div className={`shorts-media-buttons ${isActive ? 'block' : 'hidden'} absolute top-9 md:top-14 right-3 flex items-center gap-2 z-10 bg-[#00000033] backdrop-blur-lg p-1 rounded-xl`}>
                                                {currentMedia?.media?.[currentMediaIndex]?.type === "video" ?
                                                    <button onClick={toggleMute}>
                                                        {isMuted ? (
                                                            <img src={muteIcon} alt="unmute-icon" />
                                                        ) : (
                                                            <img src={unMuteIcon} alt="mute-icon" />
                                                        )}
                                                    </button> : null
                                                }
                                                <button onClick={togglePause}>
                                                    {isPaused ? (
                                                        <img src={playIcon} alt="play-icon" />
                                                    ) : (
                                                        <img src={pauseIcon} alt="pause-icon" />
                                                    )}
                                                </button>
                                                <button className="block md:hidden" onClick={handleShareClick}>
                                                    <img src={shareIcon} alt="share-icon" />
                                                </button>
                                            </div>

                                            <div className={`shorts-media-buttons ${isActive ? 'hidden md:flex' : 'hidden'} absolute top-9 md:top-11 left-3 flex items-center gap-2 z-10 bg-[#00000033] backdrop-blur-lg p-1 rounded-xl`}>
                                                <button onClick={closeShorts} className='cursor-pointer'>
                                                    <img src={backIcon} alt="back-icon" width={20} />
                                                </button>
                                            </div>

                                            {/* backButton */}
                                            <div className='absolute top-10 left-5 block md:hidden z-10'>
                                                <button onClick={closeShorts} className='cursor-pointer'>
                                                    <img src={backIcon} alt="back-icon" />
                                                </button>
                                            </div>

                                            {/* ProgressBar */}
                                            <div className={`${isActive ? 'flex' : 'hidden'} absolute top-5 md:top-6 left-5 md:left-6 w-11/12 md:w-10/12 gap-2`}>
                                                {item.media.map((media, index) => {
                                                    const videoProgress = progress[activeIndex]?.videoProgress?.[index] ?? 0;
                                                    const imageProgress = progress[activeIndex]?.imageProgress?.[index] ?? 0;

                                                    return (
                                                        <div key={index} className="h-1 w-full rounded-[30px] bg-[#747474] overflow-hidden">
                                                            <span
                                                                className="block bg-white h-full"
                                                                style={{
                                                                    width: media.type === 'video' ? `${videoProgress}%` : `${imageProgress}%`,
                                                                    transition: 'width 0.4s ease',
                                                                }}
                                                            />
                                                        </div>
                                                    );
                                                })}
                                            </div>

                                            {/* shareButton */}
                                            <div onClick={handleShareClick} className={`${isActive ? 'hidden md:flex' : 'hidden'} items-center justify-center absolute top-2 right-2 z-10 h-10 w-10 rounded-full bg-[#00000033] backdrop-blur-lg cursor-pointer`}>
                                                <img src={shareIcon} alt="share-icon" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </>
    );
};

export default ShowShorts;
