import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useAxiosSWR } from '../../../../../axiosInstance';
import { Link, useParams, useNavigate } from 'react-router-dom';
import MatchStatus from './MatchStatus';
import LiveAnimatedRedDot from '../../../common/LiveAnimatedRedDot';
import { useHorizontalScroll } from '../../../../hooks/useHorizontalScroll';
import LiveBlogInstance from './LiveBlogInstance';
import AdsImage from '../../../common/AdsImage';
import { newsAdsDesktopImage, newsAdsMobileImage, newsAdsRedirecURL } from '../../../../constants';

const Commentary = React.lazy(() => import('../../Schedule/MatchInstance/components/Commentary'));
const Scorecard = React.lazy(() => import('../../Schedule/MatchInstance/components/Scorecard'));

const Pages = [
    { key: "liveblog", label: "Live Blog" },
    { key: "ballbyballcommerntry", label: "Ball by Ball Commentary" },
    { key: "scorecard", label: "Scorecard" },
];

export default function LiveBlog() {
    const { id: slug } = useParams();
    const [liveBlogTab, setLiveBlogTab] = useState("liveblog");
    const containerRef = useRef(null);
    const searchParams = new URLSearchParams(window.location.search);
    const page = searchParams.get("page");
    const navigate = useNavigate();

    useEffect(() => {
        if (page) {
            const isValidPage = Pages.some((item) => item.key === page);
            if (!isValidPage) {
                navigate(`/live-blogs/${slug}?page=liveblog`);
            } else {
                setLiveBlogTab(page);
            }
        } else {
            setLiveBlogTab("liveblog");
        }
    }, [page, navigate, slug]);

    useHorizontalScroll(containerRef, liveBlogTab);

    const memoizedValues = useMemo(() => {
        const parts = slug.split("-");
        const newsId = parts[parts.length - 1];
        return { newsId };
    }, [slug]);

    const { newsId } = memoizedValues;
    const {
        data,
        isLoading,
        error: isError,
        mutate
    } = useAxiosSWR(`/v1/news/${newsId}`);

    const matchId = searchParams.get("matchId") || data?.match_id;

    const renderComponent = useCallback(() => {
        switch (liveBlogTab) {
            case 'liveblog':
                return <LiveBlogInstance data={data} newsId={newsId} />;

            case 'ballbyballcommerntry':
                return <React.Suspense><Commentary liveBlogMatchId={matchId} /></React.Suspense>;

            case 'scorecard':
                return <React.Suspense><Scorecard liveBlogMatchId={matchId} /></React.Suspense>;

            default:
                navigate(`/live-blogs/${data.slug}-${data.id}?page=liveblog`);
                return null;
        }
    }, [liveBlogTab, data, navigate]);

    if (!isLoading && !data && !isError) {
        return null;
    }
    if (isLoading) {
        return <div className="min-h-screen"><h2>Loading Data</h2></div>;
    }
    if (isError) {
        return (
            <div className="flex flex-col items-center justify-center text-center mt-4">
                <h2>Something Went Wrong...</h2>
                <button onClick={mutate} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4">Try Again</button>
            </div>
        );
    }

    return (
        <>
            <div className="md:hidden">
                <MatchStatus matchId={matchId} />
            </div>

            <div className="rounded-2xl md:border md:mt-4 md:bg-white md:mx-5">
                <div className='md:py-10'>
                    <div className="hidden md:block">
                        <MatchStatus matchId={matchId} />
                    </div>
                    <div className='mx-[6px] md:mx-10 mt-3 md:mt-5'>
                        <div className="border rounded-xl bg-white overflow-x-auto hideScrollbar w-full md:w-fit" ref={containerRef}>
                            <div className="flex text-sm w-max cursor-pointer p-2 gap-2">
                                {Pages.map((type) => (
                                    <Link
                                        to={`/live-blogs/${data.slug}-${data.id}?page=${type.key}`}
                                        key={type.key}
                                        data-testid={type.key}
                                    >
                                        <div
                                            className={`flex items-center overflow-hidden md:px-6 px-4 py-2.5 text-black normal-case border-[1px] rounded-xl ${liveBlogTab === type.key ? "text-white bg-blue-700 p-1 font-medium border-transparent" : "border-[#eee]"}`}
                                            onClick={() => {
                                                setLiveBlogTab(type.key);
                                            }}
                                        >
                                            {type.key === "liveblog" && (
                                                <LiveAnimatedRedDot className='mr-3' />
                                            )}
                                            {type.label}
                                        </div>
                                    </Link>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {newsId === '379100000'?
                <div className='mt-4 flex items-center justify-center'>
                    <AdsImage
                        desktopImage={newsAdsDesktopImage}
                        mobileImage={newsAdsMobileImage}
                        redirectURL={newsAdsRedirecURL}
                    />
                </div> : null
            }
            <div className='mt-4'>
                {renderComponent()}
            </div>
        </>
    );
}
