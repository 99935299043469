import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Banner from './common/Banner'
import { useAxiosSWR } from '../../../axiosInstance'
import { convertViews, getAssetUrl } from '../../helpers/general';

const authorsBanar = getAssetUrl("authorsBanar.jpg");
const authorsBanarMobile = getAssetUrl("authorsMobileBanar.jpg");
const img = getAssetUrl("authors01.png");
const post = getAssetUrl('postsicon.svg');
const views = getAssetUrl('viewsicon.svg');
const nextIcon = getAssetUrl("authorNext.svg");
const prevIcon = getAssetUrl("authorPrev.svg");

const Authors = () => {
    const itemsPerPage = 9;
    const [selectedPage, setSelectedPage] = useState(1);
    const [num, setNum] = useState(0);
    const url = `/v1/authors/?page=${selectedPage}&per_page=${itemsPerPage}`;
    const { data, isLoading, error } = useAxiosSWR(url);
    const authorsData = data?.data;
    const handlePrevPage = () => {
        setSelectedPage(prev => (prev > 1 ? prev - 1 : 1)); // Prevent going below 1
    }

    const handleNextPage = () => {
        const totalPages = Math.ceil(data?.total / itemsPerPage) || 1;
        setSelectedPage(prev => (prev < totalPages ? prev + 1 : totalPages)); // Prevent exceeding total pages
    }

    if (isLoading) {
        return <div className='container mx-auto px-[6px] md:px-4 py-8 break-words'><h2>Loading...</h2></div>
    }

    return (
        <div>
            <Banner
                title='Authors'
                content='A team of passionate cricket fans who love to express their ideas, views, perspectives, opinions and insights while analysing the game. Dynamic youngsters who relish this opportunity of writing for millions of readers.'
                largeBanner={authorsBanar}
                smallBanner={authorsBanarMobile}
                isSearchbar={true}
            />
            <div className='md:mx-[10px] lg:mx-[10px] xl:mx-[140px] md:my-[80px] mb-[40px] md:rounded-[20px] md:py-10 pb-6 pt-4 md:px-[30px] px-[6px]'>
                <div className='md:grid md:grid-cols-2 lg:grid-cols-3 gap-4'>
                    {error ?
                        <div className='container mx-auto px-[6px] md:px-4 py-8 break-words'>
                            <h2>Error fetching content.</h2>
                            <button className='bg-white rounded-md p-1 text-[#3A32D1] font-semibold px-3 hover:text-gray-300 hover:bg-[#3A32D1] transition ease-linear mt-3' onClick={() => {
                                setNum(prev => prev + 1);
                            }}>Try Again</button>
                        </div>
                        :
                        authorsData?.map((obj, index) => (
                            <Link to={`/authors/${obj?.username}`}>
                                <div className={`flex p-6 pe-1 gap-4 bg-white border rounded-[28px] ${index === 0 ? "" : "mt-4 md:mt-0"}`}>
                                    <div>
                                        <img src={obj?.info?.profile_pic} alt="authors" className='object-cover h-[122px] w-[80px] object-center rounded-xl' />
                                    </div>
                                    <div className='md:mt-2.5'>
                                        <div className='text-[#202020] text-[15px] md:text-[20px] leading-[15px] font-bold'>{obj.name}</div>
                                        <div className='text-[#6C6C6C] text-[12px] mt-[10px] md:flex gap-1 items-center'>
                                            <div className='flex gap-1 items-center'>
                                                {obj?.info?.designation} {obj?.info?.designation && '•'}
                                                <span><img src={post} alt="" /></span>
                                                {obj.total_articles} posts
                                            </div>
                                            <div className='flex gap-1 items-center'>
                                                <span className='hidden md:block'>•</span>
                                                <span><img src={views} alt="" /></span>
                                                {obj.total_views ? convertViews(obj.total_views) : 0} Views
                                            </div>
                                        </div>
                                        <button className='border border-[#3A32D129] px-[25px] py-[12px] leading-3 text-[#3A32D1] rounded-full w-max text-[14px] font-semibold mt-[22px]'>VIEW POST</button>
                                    </div>
                                </div>
                            </Link>
                        ))
                    }
                </div>
                {!error ?
                    <div className='flex justify-between items-center mt-[80px] md:mt-[120px]'>
                        <button disabled={selectedPage === 1} onClick={handlePrevPage}>
                            <img src={prevIcon} alt="prev" />
                        </button>
                        <div className='border bg-white p-2 rounded-2xl flex'>
                            {
                                Array.from({ length: data?.total_pages }, (_, index) => index + 1).map((num) => (
                                    <div
                                        key={num}
                                        onClick={() => setSelectedPage(num)}
                                        className={`py-[12px] leading-4 px-[14px] cursor-pointer ${selectedPage === num ? 'bg-[#3A32D1] rounded-lg text-white' : 'bg-transparent text-black'}`}
                                    >
                                        {num}
                                    </div>
                                ))
                            }
                        </div>
                        <button disabled={selectedPage === data?.total_pages} onClick={handleNextPage}>
                            <img src={nextIcon} alt="next" />
                        </button>
                    </div> : null
                }
            </div>
        </div >
    )
}

export default Authors
