import React from 'react';

export default function PlayerDetailsTable({ columns, data }) {
    return (
        <div className='overflow-x-auto '>
            <table className="table-auto w-full players-details-table">
                <thead>
                    <tr>
                        {columns.map((column, index) => (
                            <th
                                key={index}
                                className="px-4 py-2 bg-[#EEF0F3] text-[#222529] font-bold text-xs md:text-base text-left"
                            >
                                {column.title}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data ? data?.map((row, rowIndex) => (
                        <tr
                            key={rowIndex}
                            className={`border-b-[1px] ${rowIndex !== data?.length - 1 ? 'border-[#EEEEEF]' : 'border-transparent'}`}
                        >
                            {columns.map((column, colIndex) => (
                                <td
                                    key={colIndex}
                                    className="px-4 py-2 text-left text-[#222529] font-black text-xs md:text-sm" 
                                >
                                    {row[column.key]}
                                </td>
                            ))}
                        </tr>
                    )) : (
                        <tr><td className='text-center text-sm'>no data available</td></tr>
                    )}
                </tbody>
            </table>
        </div>
    );
}
