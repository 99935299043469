import React, { useState, useEffect, useRef } from "react";
import { getRightOrder, shimmerData } from "./helper";
import { getAssetUrl, doScrollToTop, getSanitizedArray } from "../../../../../helpers/general";
import debounce from "lodash.debounce";
import { useAxiosSWR } from "root/axiosInstance";
import { Link } from "react-router-dom";
import { ShimmerEffect } from "../../../../common/ShimmerEffect";
import { MatchFormatShortNames, getClassByFormat } from "../../constants";
import { useHorizontalScroll } from "../../../../../hooks/useHorizontalScroll";
import { getMatchOversText } from "../../../../../helpers/matchOvers";
import LiveAnimatedRedDot from "../../../../common/LiveAnimatedRedDot";
import AdsImage from "../../../../common/AdsImage";
import { matchCrouselDesktopImage, matchCrouselMobileImage, newsAdsRedirecURL } from "../../../../../constants";

const nextIcon = getAssetUrl("next-svg.svg");
const prevIcon = getAssetUrl("prev-svg.svg");
const liveIcon = getAssetUrl("red-dot.svg");

export const LiveMatchesCarousel = () => {
  const {
    data,
    error: isError,
    isLoading,
  } = useAxiosSWR("/v2/matches/live", { refreshInterval: 5000 });
  const liveMatchData = getSanitizedArray(isLoading ? shimmerData : data);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [filterType, setFilterType] = useState("All");
  const containerRef = useRef(null);
  const cardRef = useRef(null);
  const [cardWidth, setCardWidth] = useState(0);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);

  useEffect(() => {
    const handleResize = debounce(() => {
      setScreenWidth(window.innerWidth);
      if (cardRef.current) {
        setCardWidth(cardRef.current.offsetWidth);
      }
    }, 200);
    window.addEventListener("resize", handleResize);
    handleResize(); // Call handleResize once to initialize cardWidth
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (cardRef.current) {
      setCardWidth(cardRef.current.offsetWidth);
    }
  }, [cardRef.current]);

  const handleFilterChange = (filter) => {
    setFilterType(filter);
  };

  const ArrowButton = ({ direction, onClick, icon }) => (
    <button
      className={`absolute ${direction === "left" ? "left-0" : "right-0"
        } top-1/2 transform -translate-y-1/2 live-nav hidden md:flex justify-center items-center h-full w-[100px]`}
      onClick={onClick}
      style={{
        visibility:
          direction === "left"
            ? showLeftArrow
              ? "visible"
              : "hidden"
            : showRightArrow
              ? "visible"
              : "hidden",
        background: direction === 'left' ? "linear-gradient(90deg, #2F27BE 0%, rgba(255, 255, 255, 0) 100%)" : "linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #2F27BE 100%)",
      }}
    >
      <ShimmerEffect src={icon} alt={direction} className="w-16" />
    </button>
  );

  const filteredData = liveMatchData.filter((match) => {
    if (filterType === "All")
      return match.is_featured === true;
    if (filterType === "Completed")
      return match.status === "Completed" || match.status === "Cancelled";
    return match.status === filterType;
  });
  const liveMatchDataLength = liveMatchData.filter((match) => {
    return match.status === "Live";
  })?.length;
  const nextSlide = () => {
    // console.log('cardWidth nextSlide', cardWidth);
    if (containerRef.current) {
      // containerRef.current.scrollRight += cardWidth;
      containerRef.current.scroll({
        left: containerRef.current.scrollLeft + 285,
        behavior: "smooth",
      });
    }
    // console.log('AFTER ADD ->', containerRef.current.scrollLeft)
  };

  const prevSlide = () => {
    if (containerRef.current) {
      containerRef.current.scroll({
        left: containerRef.current.scrollLeft - 285,
        behavior: "smooth",
      });
    }
  };
  const handleScroll = () => {
    if (containerRef.current) {
      setShowLeftArrow(containerRef.current.scrollLeft > 0);
      setShowRightArrow(
        containerRef.current.scrollLeft <
        containerRef.current.scrollWidth - containerRef.current.clientWidth
      );
    }
  };

  const cardStyle = {
    scrollSnapAlign: "start",
  };

  if (!liveMatchData || liveMatchData.length === 0) {
    return null;
  }

  if (isError) {
    return;
  }

  return (
    <div className="relative md:bg-[#2F27BE] bg-[#312AB7] md:rounded-2xl overflow-hidden live-match-carousel">
      <FilterButtons
        filterType={filterType}
        handleFilterChange={handleFilterChange}
        liveMatchData={liveMatchData}
        liveMatchDataLength={liveMatchDataLength}
      />
      <div className="relative">
        <div
          ref={containerRef}
          className={`pb-9 flex overflow-x-auto hideScrollbar overflow-y-hidden live-match-list ${filteredData?.length > 3 ? "" : "lg:justify-center"}  ${filteredData?.length > 1 ? "" : "justify-center"}`}
          onScroll={handleScroll}
        >
          <div className={`gap-4 flex mx-4 min-h-[132px]`}>
            {filteredData.length > 0 ?
              filteredData.map((match, index) => (
                <React.Fragment key={index}>
                  <div
                    style={cardStyle}
                    className="flex-none h-[132px] w-[290px]"
                    data-matchid={match.match_id}
                  >
                    {match.status === "Live" && <LiveMatch match={match} />}
                    {match.status === "Scheduled" && <ScheduledMatch match={match} />}
                    {(match.status === "Completed" || match.status === "Cancelled") && (
                      <CompletedMatch match={match} />
                    )}
                  </div>
                  {index === 9000 && filterType === 'All' ? (
                    <div className="flex-none h-[132px] w-[290px]">
                      <AdsImage
                        desktopImage={matchCrouselDesktopImage}
                        mobileImage={matchCrouselMobileImage}
                        redirectURL={newsAdsRedirecURL}
                      />
                    </div>
                  ) : null}
                </React.Fragment>
              ))
              :
              <div className="text-white h-max my-auto">
                {isLoading == false && <>No matches are found for this filter.</>}
              </div>
            }
          </div>
        </div>
        <ArrowButton direction="left" onClick={prevSlide} icon={prevIcon} />
        {filteredData.length > 4 && (
          <ArrowButton direction="right" onClick={nextSlide} icon={nextIcon} />
        )}
      </div>
    </div>
  );
};

const filterOptions = [
  { value: "All", label: "Featured", mobileLabel: "Featured" },
  { value: "Live", label: "Live Score", mobileLabel: "Live" },
  { value: "Scheduled", label: "Upcoming", mobileLabel: "Upcoming" },
  { value: "Completed", label: "Result", mobileLabel: "Result" },
];

const FilterButtons = ({ filterType, handleFilterChange, liveMatchData, liveMatchDataLength }) => {
  const containerRef = useRef();
  useHorizontalScroll(containerRef, filterType);
  return (
    <div ref={containerRef} className="overflow-x-auto hideScrollbar md:border md:my-4 mb-4 border-[#D8E7FF] border-b border-opacity-30 md:h-9 h-11 md:rounded-full md:max-w-max md:mx-auto">
      <div className="flex justify-center text-xs md:text-base w-max">
        {filterOptions.map(({ value, label, mobileLabel }, index) =>
          (value === "All" || liveMatchData.some(match => match.status === value)) && (
            <button
              key={value}
              className={`md:py-1.5 py-3 ${filterType === value
                ? `md:bg-[#F5F9FF] md:text-[#3A32D1] text-[#F5F9FF] md:text-sm text-xs font-medium ${index === 0 ? 'md:rounded-l-full' : (index === filterOptions.length - 1 ? 'md:rounded-r-full' : '')} border-b-[3px] border-[#F5F9FF]`
                : " md:bg-[#3A32D1] bg-[#2F27BE] text-[#F5F9FF] opacity-60 md:text-sm text-xs"
                }`}
              data-testid={value}
              onClick={() => handleFilterChange(value)}
            >
              <div className={`font-semibold md:px-8 px-4 md:hidden block ${index !== 3 ? "md:border-r border-[#D8E7FF]" : ""}`}>
                {mobileLabel === "LIVE" ? mobileLabel + ` (${liveMatchDataLength})` : mobileLabel} {getFeaturedMatchCountText(liveMatchData, value)}
              </div>
              <div className={`md:px-8 px-4 hidden md:block ${index !== 3 ? "md:border-r border-[#D8E7FF]" : ""}`}>
                {label}
              </div>
            </button>
          )
        )}
      </div>
    </div>
  );
};
export const LiveMatch = ({ match }) => {
  const {
    teama,
    teamb,
    subtitle,
    format,
    status_note,
    tournament_name,
    show_pointtable,
    status,
    isShimmer,
    tag,
    status_note2
  } = match;
  const classname = isShimmer ? "bg-gray-300 loading text-opacity-0" : "";
  const oversText = {
    [teama?.team_id]: getMatchOversText(match, teama?.team_id),
    [teamb?.team_id]: getMatchOversText(match, teamb?.team_id),
  }

  return (
    <div className="bg-white rounded-2xl border border-[#E3E3E4] px-2 relative">
      <Link
        to={`/series/${match.tournament_slug}/schedule/${match.slug}/commentary`}
        onClick={doScrollToTop}
      >
        <div className={`${classname} flex justify-between items-center mt-2`}>
          <p className={`text-[10px] text-gray-600 flex-grow live-match font-medium pl-1`}>
            <span className="font-extrabold text-yellow-600 text-[11px]">{tag || ''}</span>{tag && ' • '}
            {subtitle} • {""}
            <span className="text-[#6C6C6C] border-b border-[#6C6C6C] border-dashed">{tournament_name}</span>
          </p>
          <p className={`rounded-[40px] min-w-[33px] whitespace-nowrap text-[10px] px-2 ${getClassByFormat(format)}`}>
            {MatchFormatShortNames[format] || format}
          </p>
        </div>
        {getRightOrder(teama, teamb).map((team, i) => (
          <div data-teamid={team?.team_id} key={i} className="flex items-center pb-1 pt-0.5">
            <div className="flex items-center w-[120px]">
              <div className="p-[2px] mr-0.5">
                {!isShimmer && (
                  <ShimmerEffect
                    src={team?.logo}
                    alt={team?.name}
                    className="h-5 w-5 my-auto"
                  />
                )}
              </div>
              <p className={`${classname} min-h-[17px] min-w-[28px] text-sm !leading-[17px] ${team?.scores ? "font-medium" : "text-[#6C6C6C]"} mr-2`}>
                {team?.short_name}
              </p>
            </div>
            <div className={`${classname} min-h-[16px] min-w-[83px] flex items-center whitespace-nowrap`}>
              <span className="text-[14px] leading-4 font-bold ml-4">
                {team?.scores?.replace(/\/10/g, '')}
              </span>
              {team.scores && (
                <span className="text-[10px] text-[#808080] ml-1">
                  {oversText[team?.team_id]}
                </span>
              )}
            </div>
          </div>
        ))}
        <div className="py-2 custom-border border-b">
          <div className={`${classname} min-h-[15px] w-full live-match text-[#797979] text-[10px] font-semibold overflow-hidden`}
            dangerouslySetInnerHTML={{ __html: status_note2 }}
          >
          </div>
        </div>
      </Link>
      <div className="flex items-center text-sm my-2">
        <div className=" flex items-center">
          {!isShimmer && (
            // <img className="mr-1" src={liveIcon} alt="live" />
            <div className="mr-[6px]">
              <LiveAnimatedRedDot />
            </div>
          )}
          <span className={`${classname} min-w-[40px] text-green-600`}>{status}</span>
        </div>
        <div className="ml-auto text-[12px]">
          {
            show_pointtable === 1 &&
            <Link
              to={`/series/${match.tournament_slug}/pointtable`}
              onClick={doScrollToTop}
              className={`${classname} min-h-[15px] min-w-[30px] mx-1 hover:text-[#3A32D1] text-[#6C6C6C] ${isShimmer ? '' : 'border-b border-[#6C6C6C] border-dashed'}`}>
              Table
            </Link>
          }
          <Link
            to={`/series/${match.tournament_slug}/schedule`}
            className={`${classname} min-h-[15px] min-w-[30px] mx-1 hover:text-[#3A32D1] text-[#6C6C6C] ${isShimmer ? '' : 'border-b border-[#6C6C6C] border-dashed'}`}>
            Schedule
          </Link>
        </div>
      </div>
    </div>
  );
};
export const ScheduledMatch = ({ match }) => {
  const {
    isShimmer,
  } = match;
  const classname = match.isShimmer ? "bg-gray-300 loading text-opacity-0" : "";

  return (
    <div className="bg-white rounded-2xl border border-[#E3E3E4] px-2 relative">
      <Link
        to={`/series/${match.tournament_slug}/schedule/${match.slug}/matchinfo`}
        onClick={doScrollToTop}
      >
        <div className={`${classname} flex justify-between items-center mt-2`}>
          <p className="text-[10px] text-gray-600 flex-grow live-match font-medium pl-1">
            {match.subtitle} • {" "}
            <span className="text-[#6C6C6C] border-b border-[#6C6C6C] border-dashed">{match.tournament_name}</span>
          </p>
          <p className={`rounded-[40px] max-w-[150px] whitespace-nowrap text-[10px] px-2 ${getClassByFormat(match.format)}`}>
            {MatchFormatShortNames[match.format] || match.format}
          </p>
        </div>
        {getRightOrder(match.teama, match.teamb).map((team, i) => (
          <div data-teamid={team.team_id} key={i} className="flex items-center pb-1 pt-0.5">
            <div className="flex items-center w-[120px]">
              <div className="p-[2px] mr-0.5">
                {!isShimmer && (
                  <ShimmerEffect
                    src={team?.logo}
                    alt={team?.name}
                    className="h-5 w-5 my-auto"
                  />
                )}
              </div>
              <p className={`${classname} min-h-[17px] min-w-[28px] text-sm !leading-[17px] ${team?.scores ? "font-medium" : "text-[#6C6C6C]"} mr-2`}>
                {team?.short_name}
              </p>
            </div>
          </div>
        ))}
        <div className="text-right absolute top-0 right-0 mt-6 mr-2">
          <p className={`${classname} min-h-[40px] min-w-[60px]`}>
            <span className="text-xs text-gray-500">{match.dateStrings?.string1}</span> <br />
            <span className="text-[18px] font-bold">{match.dateStrings?.string2}</span>
          </p>
        </div>
        <div>
          <p className={`${classname} min-h-[20px] w-full live-match text-[#797979] text-[10px] custom-border border-b py-2 font-semibold overflow-hidden`}>
            {match.game_state_str || "-"}
          </p>
        </div>
      </Link>
      <div className="flex items-center text-sm my-2">
        <div className=" flex items-center">
          <span className={`${classname} min-w-[40px] text-green-600`}>{match.status}</span>
        </div>
        <div className="ml-auto text-[12px]">
          {
            match?.show_pointtable === 1 &&
            <Link
              to={`/series/${match.tournament_slug}/pointtable`}
              onClick={doScrollToTop}
              className={`${classname} min-h-[15px] min-w-[30px] mx-1 hover:text-[#3A32D1] text-[#6C6C6C] ${isShimmer ? '' : 'border-b border-[#6C6C6C] border-dashed'}`}>
              Table
            </Link>
          }
          <Link
            to={`/series/${match.tournament_slug}/schedule`}
            className={`${classname} min-h-[15px] min-w-[30px] mx-1 hover:text-[#3A32D1] text-[#6C6C6C] ${isShimmer ? '' : 'border-b border-[#6C6C6C] border-dashed'}`}>
            Schedule
          </Link>
        </div>
      </div>
    </div>
  );
};
export const CompletedMatch = ({ match }) => {
  const {
    teama,
    teamb,
    subtitle,
    format,
    tournament_name,
    show_pointtable,
    status,
    isShimmer,
  } = match;
  const classname = match.isShimmer ? "bg-gray-300 loading text-opacity-0" : "";
  const oversText = {
    [teama?.team_id]: getMatchOversText(match, teama?.team_id),
    [teamb?.team_id]: getMatchOversText(match, teamb?.team_id),
  }
  return (
    <div className="bg-white rounded-2xl border border-[#E3E3E4] px-2 relative">
      <Link
        to={`/series/${match.tournament_slug}/schedule/${match.slug}/scorecard`}
        onClick={doScrollToTop}
      >
        <div className={`${classname} flex justify-between items-center mt-2`}>
          <p className="text-[10px] text-gray-600 flex-grow live-match font-medium pl-1">
            {subtitle} • {" "}
            <span className="text-[#6C6C6C] border-b border-[#6C6C6C] border-dashed">{tournament_name}</span>
          </p>
          <p className={`rounded-[40px] max-w-[150px] whitespace-nowrap text-[10px] px-2 ${getClassByFormat(format)}`}>
            {MatchFormatShortNames[format] || format}
          </p>
        </div>
        {getRightOrder(teama, teamb).map((team, i) => (
          <div data-teamid={team?.team_id} key={i} className="flex items-center pb-1 pt-0.5">
            <div className="flex items-center w-[120px]">
              <div className="p-[2px] mr-0.5">
                {!isShimmer && (
                  <ShimmerEffect
                    src={team?.logo}
                    alt={team?.name}
                    className="h-5 w-5 my-auto"
                  />
                )}
              </div>
              <p className={`${classname} min-h-[17px] min-w-[28px] text-sm !leading-[17px] ${team?.scores ? "font-medium" : "text-[#6C6C6C]"} mr-2`}>
                {team?.short_name}
              </p>
            </div>
            <div className={`${classname} min-h-[16px] min-w-[83px] flex items-center whitespace-nowrap`}>
              <span className="text-[14px] leading-4 font-bold ml-4">
                {team?.scores?.replace(/\/10/g, '')}
              </span>
              {team?.scores && (
                <span className="text-[10px] text-[#808080] ml-1">
                  {oversText[team?.team_id]}
                </span>
              )}
            </div>
          </div>
        ))}
        <div className="py-2 custom-border border-b">
          <div className={`${classname} min-h-[15px] w-full live-match text-[#797979] text-[10px] font-semibold overflow-hidden`}
            dangerouslySetInnerHTML={{ __html: match.status_note }}
          >
          </div>
        </div>
      </Link>
      <div className="flex items-center text-sm my-2">
        <span className={`${classname} min-h-[20px] min-w-[40px] text-green-600`}>{match.status}</span>
        <div className="ml-auto text-[12px]">
          {
            show_pointtable === 1 &&
            <Link
              to={`/series/${match.tournament_slug}/pointtable`}
              onClick={doScrollToTop}
              className={`${classname} min-h-[15px] mx-1 text-[#6C6C6C] hover:text-[#3A32D1] ${isShimmer ? '' : 'border-b border-[#6C6C6C] border-dashed'}`}>
              Table
            </Link>
          }
          <Link
            to={`/series/${match.tournament_slug}/schedule`}
            className={`${classname} min-h-[15px] mx-1 text-[#6C6C6C] hover:text-[#3A32D1] ${isShimmer ? '' : 'border-b border-[#6C6C6C] border-dashed'}`}>
            Schedule
          </Link>
        </div>
      </div>
    </div>
  );
};

function getFeaturedMatchCountText(liveMatchData, value) {
  try {
    if (Array.isArray(liveMatchData)) {
      let count = 0;
      if (value === "All") {
        count = liveMatchData.filter(match => match.is_featured === true).length;
      } else {
        switch (value) {
          case 'Completed':
            count = liveMatchData.filter(match => match.status === 'Completed').length;
            break;
          case 'Scheduled':
            count = liveMatchData.filter(match => match.status === 'Scheduled').length;
            break;
          case 'Live':
            count = liveMatchData.filter(match => match.status === 'Live').length;
            break;
          default:
            break;
        }
      }

      if (count) return `(${count})`;
    }

    return '';
  } catch (error) {
    return '';
  }
}