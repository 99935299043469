import { TrackGoogleAnalyticsEvent } from "../helpers/analytics";

export default function useShare() {
    let showShareButton = false;

    if (navigator.share) {
        showShareButton = true;
    }

    const handleShareClick = (e, title, text, url, AnalyticsCategory, action) => {
        e.preventDefault();
        try {
            if (navigator.share) {
                navigator.share({
                    title: title || '',
                    text: text || '',
                    url: url || '',
                });
                return;
            }
        } catch (error) {
            console.log('Sharing failed.., Error is ', error);
        }
        try {
            navigator.clipboard.writeText(url);
        } catch (error) {
        }
        TrackGoogleAnalyticsEvent(AnalyticsCategory, action, window.location.pathname)
    };

    return { showShareButton, handleShareClick }
}