import React, { useEffect, useMemo } from 'react';
import { useAxiosSWR } from '../../../../../axiosInstance';
import { useParams } from 'react-router-dom';
import { getSanitizedArray } from '../../../../helpers/general';
import { useLoadSocialMediaOnce } from '../../../../hooks/useLoadSocialMediaOnce';

const Pages = [
    { key: "liveblog", label: "Live Blog" },
    { key: "ballbyballcommerntry", label: "Ball by Ball Commentary" },
    { key: "scorecard", label: "Scorecard" },
];

export const LiveBlogTests = () => {
    const { reprocessEmbeds } = useLoadSocialMediaOnce();
    const { id: slug } = useParams();

    const memoizedValues = useMemo(() => {
        const parts = slug.split("-");
        const newsId = parts[parts.length - 1];
        return { newsId };
    }, [slug]);

    const { newsId } = memoizedValues;

    const {
        data: newsBlocks,
        isLoading: loadingNewsBlocks,
        error: isNewsBlocksError,
        mutate: refetchNewsBlocks
    } = useAxiosSWR(`/v1/news/${newsId}/newsBlocks`);

    useEffect(() => {
        let timerId;
        timerId = setInterval(() => {
            console.log('Refetching live blocks at ' + (new Date().toString()))
            refetchNewsBlocks();
        }, 5000);

        return () => {
            clearInterval(timerId);
        }
    }, [])

    const sanitizedBlocks = getSanitizedArray(newsBlocks);

    useEffect(() => {
        for (let i = 0; i < sanitizedBlocks.length; i++) {
            const content = sanitizedBlocks[i]?.body;
            if ((content + "").includes("twitter") || (content + "").includes("www.instagram")) {
                reprocessEmbeds();
                break;
            }
        }
    },[sanitizedBlocks]);

    if (isNewsBlocksError) {
        return <div className='p-10'>
            <h6>Error Occurred !!. <button onClick={refetchNewsBlocks}>Click Here To Retry</button></h6>
        </div>
    }

    if (loadingNewsBlocks) {
        return <div className='p-10'>
            <h6>Loading... !! Please wait..</h6>
        </div>
    }

    if (sanitizedBlocks.length === 0) {
        return <div className='p-10'>
            <h6>No Blocks Found for Article Id - {newsId}</h6>
        </div>
    }

    return <NewsBlocks newsBlocks={sanitizedBlocks} />
}
/*

{
    "id": 2,
    "title": "This is my first new block Twitter",
    "body": "Hell this is html content...",
    "blockTime": "2025-03-01T04:30:58.368Z"
}

*/
const IndiVidualBlock = ({ id, title, body, blockTime }) => {
    console.log('Rendering Block with ID - ' + id);
    return (
        <>
            <div className="pb-0 px-6 py-2 relative">
                <h6>ID - {id}</h6>
                <h6>Title - {title}</h6>
                <h6>blockTime - {blockTime}</h6>
                <div
                    className={`newsContentDiv font-[400]`}
                    dangerouslySetInnerHTML={{ __html: body }}
                />
            </div>
            <div style={{ height: '12px' }}></div>
            <div style={{ borderTop: '1px solid black' }}></div>
            <div style={{ height: '12px' }}></div>
        </>
    )
}

const MemoizedIndiVidualBlock = React.memo(IndiVidualBlock);

function NewsBlocks({ newsBlocks }) {
    if (newsBlocks.length === 0) {
        return (<div>
            No blocks found
        </div>)
    }
    return (
        <div>
            {
                newsBlocks.map(block => {
                    const { id, title, body, blockTime } = block;
                    return (<div>
                        <MemoizedIndiVidualBlock
                            id={id}
                            title={title}
                            body={body}
                            blockTime={blockTime}
                        />
                    </div>)
                })
            }
        </div>
    )
}