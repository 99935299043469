import axios from "axios";
import useSWR from "swr";
import { getCGullyLoginToken, setCGullyLoginToken } from "./src/helpers/authUtils";
// import { getMatch78995, MatchInfoFakeResponse78995 } from "./fakeResponse";
// import { SeriesAPINewsFakeResponse } from "./fakeResponse";

// Create an instance with baseURL set
const axiosInstance = axios.create({
  baseURL: process.env.API_BASE_URL,
});

const fetcher = (url) => axiosInstance.get(url).then((res) => res.data);

const EMPTY_OBJ = {};
export const useAxiosSWR = (url, config = EMPTY_OBJ) => {
  const { data, error, mutate, isLoading } = useSWR(url, fetcher, {
    revalidateOnFocus: false,
    shouldRetryOnError: false,
    ...config,
  });

  return {
    data,
    error,
    mutate,
    isLoading,
  };
};

const handleUnauthorized = () => {
  setCGullyLoginToken("");
  window.location.href = window.location.href;
};
const randomId = function(length = 6) {
  return Math.random().toString(36).substring(2, length+2);
};
export function getSessionId () {
  let id = localStorage.getItem("sessionId")
  if(!id){
    id = `${randomId(8)}-${randomId(4)}-${randomId(4)}-${randomId(4)}-${randomId(12)}`
    localStorage.setItem("sessionId",id)
  }
  return id
}
const isInternetAvailable = () => {
  return window.navigator.onLine;
};
axiosInstance.interceptors.request.use(
  config => {
    if (!isInternetAvailable()) {
      return Promise.reject(new Error("No internet connection"));
    }
    config.headers["X-cricketgully-resolution"] = `${window.innerWidth}x${window.innerHeight}`;
    config.headers["X-session-id"] = getSessionId();
    config.headers["x-cricketgully-signature"] = 'cricketgully.com';
    config.headers["x-cricketgully-check"] = new Date().getTime();
    try {
      config.headers["X-cricketgully-timeZone"] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    } catch (error) {
      config.headers["X-cricketgully-timeZone"] = "Asia/Calcutta"
    }
    const token = getCGullyLoginToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  error => Promise.reject(error)
);


axiosInstance.interceptors.response.use(
  response => {
    // const isLocal = window.location.hostname === "localhost" || window.location.hostname === "dev.cricketgully.com";
    // if (response.config.url === "/v1/series/128777/news") {
    //   // return SeriesAPINewsFakeResponse;
    // } else if (isLocal && response.config.url === "v1/matches/78995/info") {
    //   return MatchInfoFakeResponse78995;
    // } else if (isLocal && response.config.url === "v1/matches/78995") {
    //   return getMatch78995();
    // }
    return response.data ;
  },
  error => {
    const apiUrl = error?.request?.responseURL;
    // if (typeof apiUrl === 'string' && apiUrl.includes('/user/profile')) {
    //   return Promise.reject(error);
    // }
    if (error.response && error.response.status === 401) {
      handleUnauthorized();
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
