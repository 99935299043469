import React, { useRef, useState } from 'react'
import { getAssetUrl } from '../../../../helpers/general';
import { ShimmerEffect } from '../../../common/ShimmerEffect';

const playerImage = 'https://s3-alpha-sig.figma.com/img/4e2d/c5b1/6a03cf8ac5b2c237813699734fefe95c?Expires=1736121600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=ZpnccfR9ECszCybs6G~~tgs117B6qQ2TphV1Yw-eV1Z~1-8slXDHeAxNxXiVyNR6gvBRSjDxMBaqEwzGxROdysWAnFrUbH3pJrEmPrYSrvQVjlRmGy~m0GNOaSQa~N7HCLblNQf-PzJDogYeGm7QcCHITMKbrZWE6MFNiL4qyd7a9~rCp~cv4sTTOdozCjS-bhK7ydFI0Yl6C6BRNGR3Ybu3KUIiB9qj5h0ZRWmS2u6QwalczqRQMaHEjJuSg2XGgd9GGOs1AOCndYGiLKsvD4~voe9B-iWeA3iNJNxv-mYqs61Ub7jH7fXxOIDSYs7eiOsO6QM7hSar2acbmTgqKA__'
const nextIcon = getAssetUrl('next-svg.svg');
const prevIcon = getAssetUrl('prev-svg.svg');

export default function PopularPlayers({ data }) {

    const containerRef = useRef(null);
    const [showLeftArrow, setShowLeftArrow] = useState(false);
    const [showRightArrow, setShowRightArrow] = useState(true);

    const nextSlide = () => {
        if (containerRef.current) {
            containerRef.current.scroll({
                left: containerRef.current.scrollLeft + 285,
                behavior: "smooth",
            });
        }
    };

    const prevSlide = () => {
        if (containerRef.current) {
            containerRef.current.scroll({
                left: containerRef.current.scrollLeft - 285,
                behavior: "smooth",
            });
        }
    };

    const handleScroll = () => {
        if (containerRef.current) {
            setShowLeftArrow(containerRef.current.scrollLeft > 0);
            setShowRightArrow(
                containerRef.current.scrollLeft <
                containerRef.current.scrollWidth - containerRef.current.clientWidth
            );
        }
    };

    const ArrowButton = ({ direction, onClick, icon }) => (
        <button
            className={`absolute ${direction === "left" ? "left-0" : "right-0 justify-end"
                } top-1/2 transform -translate-y-1/2 live-nav hidden md:flex items-center opacity-100 h-full w-[100px]`}
            onClick={onClick}
            style={{
                visibility:
                    direction === "left"
                        ? showLeftArrow
                            ? "visible"
                            : "hidden"
                        : showRightArrow
                            ? "visible"
                            : "hidden",
                background: direction === 'left' ? "linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)" : "linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)",
            }}
        >
            <ShimmerEffect src={icon} alt={direction} className="w-16" />
        </button>
    );

    return (
        <div className='relative'>
            <div ref={containerRef} onScroll={handleScroll} className='mb-9 flex overflow-x-auto hideScrollbar overflow-y-hidden gap-4'>
                {data?.map((info, index) => (
                    <div key={index} className='flex justify-center items-center border border-[#DAE6F8] rounded-2xl min-w-[132px] h-[186px]'>
                        <div className='text-center'>
                            <ShimmerEffect src={playerImage} alt='player-img' className='rounded-full h-20 w-20' />
                            <h6 className='text-[#202020] font-bold text-base mt-3 leading-5'>
                                {info.name.split(' ').map((part, i) => (
                                    <React.Fragment key={i}>
                                        {part}
                                        {i !== info.name.split(' ').length - 1 && <br />}
                                    </React.Fragment>
                                ))}
                            </h6>
                            <span className='text-[#6C6C6C] font-normal text-xs'>{info.role}</span>
                        </div>
                    </div>
                ))}
            </div>
            <ArrowButton direction="left" onClick={prevSlide} icon={prevIcon} />
            {data?.length > 4 && (
                <ArrowButton direction="right" onClick={nextSlide} icon={nextIcon} />
            )}
        </div>
    )
}