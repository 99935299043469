import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useAxiosSWR } from "../../../../../../axiosInstance";
import { doScrollToTop, getAssetUrl, isLiveNewsTag } from "../../../../../helpers/general";
import { headingTag } from "../../../../../constants";
import { pushGoogleAd } from "../../../../common/GoogleAdUnit";
import { formatDate } from "../../../../../helpers/dates";
import { useLoadSocialMedia } from "../../../../../hooks/useLoadSocialMedia";
import { useHackNx } from "../../../../../hooks/useHackNx";

const view = getAssetUrl("authorsInstanceViews.svg");

const LiveBlog = ({ articleId }) => {
    const containerRef = useRef(null);
    const fontSize = document.body.clientWidth > 700 ? 18 : 14;

    const {
        data: dataObj,
        isLoading,
        error,
        mutate
    } = useAxiosSWR(`/v1/news/${articleId ? articleId : ''}`);
    const isLiveArticle = true;
    useEffect(() => {
        let timerId;
        if (isLiveArticle) {
            timerId = setInterval(() => {
                mutate();
            }, 10000);
        }

        return () => {
            clearInterval(timerId);
        }
    }, [isLiveArticle])

    if (!articleId) return null;

    const author = dataObj?.author;
    const dataObject = dataObj || {};
    let htmlContent = (dataObject?.content || '') + '';
    const isLiveClass = isLiveNewsTag(dataObj?.info?.isLive) ? "isLive" : "";

      useLoadSocialMedia(dataObject);

    if (htmlContent?.includes('{{GOOGLE_AD_1}}')) {
        htmlContent = htmlContent.replace(
            /{{GOOGLE_AD_1}}/g,
            `<div class="flex justify-center my-2">
                <ins class="adsbygoogle"
                style="display:block; text-align:center;"
                data-ad-layout="in-article"
                data-ad-format="fluid"
                data-ad-client="ca-pub-3220816308063959"
                data-ad-slot="3389217727"></ins>
            </div>`
        )
    }

    useEffect(() => {
        if (htmlContent && htmlContent.includes("adsbygoogle")) {
            pushGoogleAd(" news details page");
        }
    }, [htmlContent])

    const { setNx } = useHackNx({ preFixStr: 'matchInstanceLiveBlog'});
    useEffect(() => {
        let count = 0;
        if (containerRef.current && dataObj) {
            const current = containerRef.current;
            try {
                const tableElements = current.getElementsByTagName("table");
                for (let i = 0; i < tableElements.length; i++) {
                    const currentTable = tableElements[i];
                    const wrapper = document.createElement("div");
                    wrapper.className = "table-wrapper";
                    wrapper.style.overflowX = "auto";
                    wrapper.style.width = "100%";
                    currentTable.parentElement.replaceChild(wrapper, currentTable);
                    wrapper.appendChild(currentTable);
                }
                const headingElements = current.querySelectorAll("h1, h2, h3, h4, h5, h6");
                headingElements.forEach(heading => {
                    const tagName = heading.tagName.toLowerCase();
                    const adjustment = headingTag[tagName];
                    heading.style.fontSize = `${fontSize + adjustment}px`;
                });
            } catch (error) {
            } finally {
                console.log(`setting nx to -100, elm updated count - ${count}`)
                setNx(-100);
            }
        } else {
        }
    }, [containerRef, dataObj, fontSize]);

    if (isLoading) {
        return <div className="mt-4 text-center">Loading ....</div>
    }

    if (error) {
        return (
            <div className="flex flex-col items-center justify-center text-center mt-4">
                <h2>Something Went Wrong...</h2>
                <button onClick={mutate} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4">Try Again</button>
            </div>
        )
    }

    return (
        <div className="bg-white rounded-2xl border mx-[6px] md:mx-5 mt-4 py-3 overflow-hidden">
            <div className="px-4 md:px-7 pt-3">
                <h1 className={`${dataObject.title.split(" ").length >= 22 && isFantasyNews ? 'md:text-[24px]' : 'md:text-3xl'} font-semibold text-lg pt-2 pb-3 md:leading-[32px] leading-[27px]`}
                >{dataObject.title}</h1>
            </div>
            <div className="flex flex-wrap justify-between items-center gap-3 px-4 md:px-7">

                <p className="lg:text-sm text-[#6c6c6c96] text-[10px] font-semibold">
                    {author?.username ? <Link className="text-[#3A32D1]" to={`/authors/${author?.username}`}>By {dataObject.author.name}</Link> : `By ${author?.name || "CricketGully"}`}
                </p>

                <div className='flex items-center gap-2 text-center'>
                    <div className='w-max mx-auto'>
                        <img src={view} alt="" />
                    </div>
                    <div className='text-[10px] md:text-[12px] md:font-medium text-[#B2B3B5]'>
                        Views
                    </div>
                    <div className='text-[10px] md:text-[12px] md:font-medium text-[#6C6C6C] text-center'>
                        {dataObj?.cnt_views ? dataObj?.cnt_views?.toLocaleString("en-IN") : 0}
                    </div>
                </div>
            </div>
            <div className="md:px-10 px-4 py-6 pt-3 relative">
                <div
                    ref={containerRef}
                    className={`leading-[${fontSize + 4}] newsContentDiv font-[400] ${isLiveClass}`}
                    style={{ fontSize: `${fontSize}px`, fontFamily: "Poppins, sans-serif" }}
                    dangerouslySetInnerHTML={{ __html: htmlContent }}
                />
            </div>
            <div className="flex gap-2 md:pt-8 py-4 md:justify-start justify-center flex-wrap text-nowrap px-4 md:px-10">
                {dataObject?.tags?.map((tag, index) => (
                    <Link key={index} to={`/tag/${encodeURIComponent(tag.keyword_slug)}`} onClick={doScrollToTop}>
                        <div color="indigo" className="text-[#3A32D1] bg-[#3A32D1] bg-opacity-10 font-semibold md:text-[18px] text-xs px-6 md:px-[24px] md:py-3 py-2 rounded-[50px]">
                            {tag.keyword}
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    )
}

export default LiveBlog;
