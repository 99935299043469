import React, { useEffect, useMemo, useRef, useState, useContext } from "react";
import { Link, useLocation, useParams, useSearchParams } from "react-router-dom";
import { useAxiosSWR } from "root/axiosInstance";
import AppContext, { URL_TYPES } from "src/context";
import { NewsNames, SocialLinksComponent, socialLinksV2 } from "./general";
import { HiMiniXMark } from "react-icons/hi2";
import { NewsFAQs } from "./newsFAQs";
import { TableOfContents } from "./fantasyComponents/TableOfContents";
import { getAssetUrl, doScrollToTop, isLiveNewsTag, getSanitizedWinPer } from "../../../helpers/general";
import { AnalyticsCategory, TrackGoogleAnalyticsEvent } from "../../../helpers/analytics";
import { getMobileDetect, headingTag, showGoogleAd, SlotProps } from "../../../constants";
import GoogleAdUnit, { pushGoogleAd } from "../../../../src/components/common/GoogleAdUnit";
import { useNewDetailToolbarShowHideOnScroll } from "../../../hooks/useShowHideOnScroll";
import CustomBlock from "./fantasyComponents/CustomBlock";
import { useLoadSocialMedia } from "../../../hooks/useLoadSocialMedia";
import { CompletedMatch, LiveMatch, ScheduledMatch } from "../Schedule/MatchInstance/component";
import { getRightOrder } from "../Home/common/liveMatchesCarousel/helper";
import LiveAnimatedRedDot from "../../common/LiveAnimatedRedDot";
import { useHackNx } from "../../../hooks/useHackNx";
const SEOMeta = React.lazy(() => import("../Home/common/SEOMeta"));
const NewsMatchInfo = React.lazy(() => import("./fantasyComponents/NewsMatchInfo"));
const FantasyCommon = React.lazy(() => import("../../common/FantasyCommon"));
const view = getAssetUrl("authorsInstanceViews.svg");

const LinksByType = {
    top_story: '/cricket-top-story',
    weekly_recap: '/cricket-news',
    news: '/cricket-news',
    fantasy: '/fantasy-cricket-tips'
};

const CustomBlockData = {
    heading: "Disclaimer",
    content: "This is a team that is created on the basis of the author's knowledge, analysis, and judgment. While selecting your team, do take note of the points mentioned and make your own decision.",
};

const IndPak = () => {
    const { id: slug } = useParams();
    const [seoMeta, setSEOMeta] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [fontSize, setFontSize] = useState(document.body.clientWidth > 700 ? 18 : 14);
    const [isContentExpanded, setIsContentExpanded] = useState(false);
    const containerRef = useRef();
    const { pathname: pathName } = useLocation();
    const [searchParams] = useSearchParams();

    const memoizedValues = useMemo(() => {
        const parts = slug.split("-");
        const newsId = parts[parts.length - 1];
        return { newsId };
    }, [slug]);
    const { newsId } = memoizedValues;
    const {
        data: dataObj,
        isLoading,
        error: isError,
        mutate
    } = useAxiosSWR(`/v1/news/${newsId}`);

    const showParticularArticleAd = Number(newsId) === 3536;

    const matchId = searchParams.get("matchId");
    const finalMatchId = matchId || 87006;
    const { data, error, isLoading: isMatchLoading, mutate: matchesRetryFn } = useAxiosSWR(`v1/matches/${finalMatchId}`);
    const LiveMatchStatus = data && data?.match?.status === "Live";
    useEffect(() => {
        let timerId;
        if (LiveMatchStatus) {
            timerId = setInterval(() => {
                matchesRetryFn();
            }, 10000);
        }

        return () => {
            clearInterval(timerId);
        }
    }, [LiveMatchStatus])

    const matchData = data?.match;
    const liveScore = data?.info?.live_score;
    const teamA = matchData?.teama;
    const teamB = matchData?.teamb;
    const orderedTeams = getRightOrder(matchData?.teama, matchData?.teamb);
    const renderLiveMatchIcon = (index) => {
        if (index + 1 == matchData?.live_inning_number) {
            return (
                // <img src={liveIcon} className=" h-2 mt-2 mr-1" />
                <div className="mt-[10px] mr-1">
                    <LiveAnimatedRedDot height="5px" width="5px" />
                </div>
            );
        } else {
            return <span className="ml-2">&nbsp;</span>;
        }
    };
    const renderCurrentRRLine = () => {
        if (matchData?.status === "Completed") {
            return null;
        }
        const arr = [];
        liveScore?.runrate && liveScore?.runrate > 0
            ? arr.push(`Current RR: ${liveScore?.runrate}`)
            : "";
        liveScore?.required_runrate && liveScore?.required_runrate > 0
            ? arr.push(`Required RR: ${liveScore?.required_runrate}`)
            : "";
        return (
            <div className="text-[#808080] text-[10px] md:text-[14px]">
                {arr.join(" • ")}
            </div>
        );
    };
    const chartData = useMemo(() => {
        if (!teamA || !teamB) {
            return []
        }
        return [teamA, teamB].map(t => ({
            logoUrl: t.logo,
            name: (t.short_name + "").slice(0, 3),
            percentage: t.win_per ? getSanitizedWinPer(t.win_per) : null,
        }))
    }, [teamA, teamB]);

    const renderMatchStatus = () => {
        switch (matchData?.status) {
            case "Live":
                return <LiveMatch chartData={chartData} matchData={matchData} tieChance={matchData?.tie_per} orderedTeams={orderedTeams} renderCurrentRRLine={renderCurrentRRLine} renderLiveMatchIcon={renderLiveMatchIcon} liveScore={liveScore} />;
            case "Completed":
                return <CompletedMatch matchData={matchData} orderedTeams={orderedTeams} liveScore={liveScore} />;
            case "Scheduled":
            default:
                return <ScheduledMatch matchData={matchData} orderedTeams={orderedTeams} />;
        }
    };

    const author = dataObj?.author;
    const isLiveArticle = isLiveNewsTag(dataObj?.info?.isLive)
    const { setNx } = useHackNx();

    useEffect(() => {
        let timerId;
        if (isLiveArticle) {
            timerId = setInterval(() => {
                mutate();
            }, 10000);
        }

        return () => {
            clearInterval(timerId);
        }
    }, [isLiveArticle])

    const appContext = useContext(AppContext);

    const newsTitle = dataObj?.title;
    useEffect(() => {
        const urlInfoObj = {
            type: URL_TYPES.NEWS_DETAILS,
            path: pathName,
            reqState: {
                data: {
                    title: newsTitle,
                },
            }
        };
        appContext.updateUrlInfo(urlInfoObj);
    }, [pathName, newsTitle]);

    const isFantasyNews = dataObj?.type === 'fantasy';
    const customBlocks = dataObj?.info?.customBlocks;

    const dataObject = dataObj || {};
    let htmlContent = (dataObject?.content || '') + '';

    if (htmlContent?.includes('{{GOOGLE_AD_1}}')) {
        htmlContent = htmlContent.replace(
            /{{GOOGLE_AD_1}}/g,
            `<div class="flex justify-center my-2">
                <ins class="adsbygoogle"
                style="display:block; text-align:center;"
                data-ad-layout="in-article"
                data-ad-format="fluid"
                data-ad-client="ca-pub-3220816308063959"
                data-ad-slot="3389217727"></ins>
            </div>`
        )
    }

    useEffect(() => {
        if (htmlContent && htmlContent.includes("adsbygoogle")) {
            pushGoogleAd(" news details page");
        }
    }, [htmlContent])

    useEffect(() => {
        if (dataObject?.info) {
            setSEOMeta({
                title: dataObject?.info?.metaTitle || dataObject?.title,
                description: dataObject?.info?.metaDescription,
                image: dataObject?.image
            });
        }
        if (dataObject?.info?.showReadMore) {
            setIsContentExpanded(true)
        }
    }, [dataObject]);
    const current = containerRef.current;
    useEffect(() => {
        let count = 0;
        if (current && dataObj) {
            try {
                const tableElements = current.getElementsByTagName("table");
                for (let i = 0; i < tableElements.length; i++) {
                    const currentTable = tableElements[i];
                    const wrapper = document.createElement("div");
                    wrapper.className = "table-wrapper";
                    wrapper.style.overflowX = "auto";
                    wrapper.style.width = "100%";
                    currentTable.parentElement.replaceChild(wrapper, currentTable);
                    wrapper.appendChild(currentTable);
                    count++;
                }
                const headingElements = current.querySelectorAll("h1, h2, h3, h4, h5, h6");
                headingElements.forEach(heading => {
                    const tagName = heading.tagName.toLowerCase();
                    const adjustment = headingTag[tagName];
                    heading.style.fontSize = `${fontSize + adjustment}px`;
                    count++;
                });

            } catch (error) {
            } finally {
                console.log(`setting nx to -100, elm updated count - ${count}`)
                setNx(-100);
            }
        } else {
        }
    }, [current, dataObj, fontSize]);


    const newsType = dataObject?.type;
    useEffect(() => {
        setIsContentExpanded(false);
    }, [slug]);

    useLoadSocialMedia(dataObject);
    const topAdHeightPx = searchParams.get("topAdHeightPx");

    const md = getMobileDetect();
    const isMobile = Boolean(md.mobile());
    const dumbStyle = useMemo(() => {
        if (!isMobile || !topAdHeightPx) {
            return {};
        }

        if (isMobile && topAdHeightPx.includes("px")) {
            return {
                height: topAdHeightPx,
                minHeight: topAdHeightPx
            }
        }

    }, [searchParams, isMobile]);

    const newsDetailToolBarRef = useRef(null);
    const newsDetailLiveTagRef = useRef(null);
    useNewDetailToolbarShowHideOnScroll(newsDetailToolBarRef, newsDetailLiveTagRef);

    if (!isLoading && !dataObject && !isError) {
        return null;
    }
    if (isLoading || isMatchLoading) {
        return <div className="min-h-screen">Loading Data !</div>;
    }
    if (isError || error) {
        return (
            <div></div>
        )
    }

    const isLiveClass = isLiveNewsTag(dataObj?.info?.isLive) ? "isLive" : "";

    return (
        <div>
            {showPopup && <div className="overlay z-40" onClick={handleClosePopup} />}
            <div>
                {showPopup && (
                    <div className="modal-content fixed bg-white shadow-md md:py-4 md:px-[30px] p-4 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-40 rounded-2xl">
                        <SocialLinksComponent
                            socialLinks={socialLinksV2}
                            className="container"
                            type="share"
                            textInputValue={window.location.href}
                            closeIcon={<HiMiniXMark className="md:w-6 md:h-6 w-5 h-5 border border-[#E3E3E4] text-gray-400 rounded-full cursor-pointer" onClick={handleClosePopup} />} />
                    </div>
                )}
                <div className="rounded-2xl md:border md:mt-4 md:bg-white md:mx-0 mx-[6px] mt-[6px]">
                    <div className={`md:pt-10 article-font relative ${isContentExpanded ? 'overflow-hidden md:rounded-b-xl' : ""} `}
                        style={{ maxHeight: isContentExpanded ? '1000px' : 'none' }}
                    >
                        {isMobile && (showGoogleAd || Boolean(showParticularArticleAd)) &&
                            <div style={dumbStyle} className="px-1 ___overflow-hidden hideScrollbar __flex __items-center __justify-center">
                                <GoogleAdUnit insCustomClass='mt-1 mb-1' slotProps={SlotProps.Mobile_Article_Page_Top_Fluid} />
                            </div>
                        }
                        {dataObj !== undefined ? <>
                            <div>
                                {seoMeta && <React.Suspense><SEOMeta data={seoMeta} /></React.Suspense>}
                                <div className="bg-white rounded-2xl md:rounded-none border md:border-none">
                                    <div className="text-[#646464] md:text-sm text-[9px] inline-block px-4 md:px-10 md:pt-0 pt-4">
                                        <Link to={"/"} className="text-[#3A32D1]"> Home </Link>
                                        <span>{">"}</span>
                                        <Link to={LinksByType[dataObj.type] || '/cricket-news'}>
                                            <span className="ml-1 text-[#3A32D1]">{NewsNames[dataObj.type]}</span>
                                        </Link>
                                    </div>
                                    <div className="px-4 md:px-10">
                                        <h1
                                            className={`${dataObject.title.split(" ").length >= 22 && isFantasyNews ? 'md:text-[24px]' : 'md:text-3xl'} font-semibold text-lg pt-2 pb-3 md:leading-[32px] leading-[27px]`}
                                        >{dataObject.title}</h1>
                                        <div data-testid="all_device_container" className="flex items-center justify-between">
                                            <div className="flex flex-wrap items-center gap-3">

                                                <p className="lg:text-sm text-[#6c6c6c96] text-[10px] font-semibold">
                                                    {author?.username ? <Link className="text-[#3A32D1]" to={`/authors/${author?.username}`}>By {dataObject.author.name}</Link> : `By ${author?.name || "CricketGully"}`}
                                                </p>
                                            </div>
                                            <div className='flex items-center gap-2 text-center'>
                                                <div className='w-max mx-auto'>
                                                    <img src={view} alt="" />
                                                </div>
                                                <div className='text-[10px] md:text-[12px] md:font-medium text-[#B2B3B5]'>
                                                    Views
                                                </div>
                                                <div className='text-[10px] md:text-[12px] md:font-medium text-[#6C6C6C] text-center'>
                                                    {dataObj?.cnt_views ? dataObj?.cnt_views?.toLocaleString("en-IN") : 0}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`md:p-5 md:rounded-2xl md:border md:bg-white md:mx-10 bg-[#312AB7] py-5 mt-3`}>
                                        <div className="mx-5 md:mx-0">
                                            {renderMatchStatus()}
                                        </div>
                                    </div>
                                    {showGoogleAd || Boolean(showParticularArticleAd) ?
                                        <>
                                            {isMobile ?
                                                <div className="flex justify-center overflow-x-hidden overflow-y-hidden"> {/* max-h-[260px] */}
                                                    <GoogleAdUnit insCustomClass='mt-2' slotProps={SlotProps.Mobile_News_Details_Below_Image} />
                                                </div>
                                                :
                                                <div className="flex justify-center overflow-x-hidden">
                                                    <GoogleAdUnit insCustomClass='mt-2' slotProps={SlotProps.Desktop_News_Details_Below_Image} />
                                                </div>
                                            }
                                        </> : null
                                    }
                                    {((isFantasyNews && dataObj?.match_id) || (Array.isArray(customBlocks) && customBlocks.length > 0)) &&
                                        <TableOfContents newsObj={dataObj} />
                                    }

                                    {htmlContent && htmlContent.trim().length > 0 && <div className="md:px-10 md:pt-10 pb-0 px-4 py-6 relative">
                                        <div
                                            ref={containerRef}
                                            className={`leading-[${fontSize + 4}] newsContentDiv font-[400] ${isLiveClass}`}
                                            style={{ fontSize: `${fontSize}px` }}
                                            dangerouslySetInnerHTML={{ __html: htmlContent }}
                                        />
                                    </div>}
                                    {
                                        !isFantasyNews &&
                                        <div className="flex gap-2 md:pt-8 py-4 md:justify-start justify-center flex-wrap text-nowrap px-4 md:px-10">
                                            {dataObject?.tags?.map((tag, index) => (
                                                <Link key={index} to={`/tag/${encodeURIComponent(tag.keyword_slug)}`} onClick={doScrollToTop}>
                                                    <div color="indigo" className="text-[#3A32D1] bg-[#3A32D1] bg-opacity-10 font-semibold md:text-[18px] text-xs px-6 md:px-[24px] md:py-3 py-2 rounded-[50px]">
                                                        {tag.keyword}
                                                    </div>
                                                </Link>
                                            ))}
                                            <div data-testid="mobile_site_thank" className="block md:hidden md:pt-0 md:bg-none w-full">
                                                <div className='py-3'>
                                                    <div className="flex items-center min-w-[50px]">
                                                        <span className="custom-border-left mr-3 md:mr-6"></span>
                                                        <p className='text-sm text-[#787878]'>Thank You</p>
                                                        <span className="custom-border-right ml-3 md:ml-6"></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <NewsFAQs content={dataObj?.info?.faqs} />
                                </div>
                                {isContentExpanded && (
                                    <div className="absolute bottom-0 left-0 right-0 flex md:rounded-b-xl items-end justify-center w-full pb-4 h-[150px] bg-gradient-to-t from-white to-transparent">
                                        <button
                                            onClick={() => setIsContentExpanded(false)}
                                            className="text-[#3A32D1] font-semibold text-xs bg-white rounded-full py-3 px-8 shadow-lg"
                                        >
                                            Read More
                                        </button>
                                    </div>
                                )}
                                {((isFantasyNews && dataObj?.match_id) || (Array.isArray(customBlocks) && customBlocks.length > 0)) &&
                                    <div className="md:px-10">
                                        <React.Suspense><NewsMatchInfo newsObj={dataObj} /></React.Suspense>
                                    </div>
                                }
                            </div>
                            {!isFantasyNews &&
                                <>
                                    <div data-testid="desktop_thank_you" className='hidden md:block px-10 pt-4'>
                                        <div className="flex items-center min-w-[50px]">
                                            <span className="custom-border-left mr-3 md:mr-6"></span>
                                            <p className='text-md text-[#787878]'>Thank You</p>
                                            <span className="custom-border-right ml-3 md:ml-6"></span>
                                        </div>
                                    </div>
                                </>
                            }
                        </> :
                            <div>
                                <h3>No Data Found...</h3>
                            </div>
                        }
                    </div>
                </div>
            </div>
            {
                isFantasyNews && dataObj?.match_id &&
                <>
                    <div className="mt-5 border bg-white rounded-2xl" data-testid="dump_king_container">
                        <div className="article-font bg-[#E8F1FF] rounded-t-[14px] md:text-2xl text-base leading-6 p-4 font-bold">
                            <div className="flex items-center gap-2 md:justify-start justify-center">
                                <h2 className='text-base md:text-2xl'>Dream11 Team For This Match</h2>
                            </div>
                        </div>

                        <div>
                            <div className='md:px-[30px] md:py-8 py-4 mx-[6px] md:mx-0'>
                                <React.Suspense><FantasyCommon matchId={dataObject?.match_id} readFullArticle={false} pageType={"fantasy-details"} /></React.Suspense>
                                <div className="flex gap-2 md:pt-8 pt-4 md:justify-start justify-center flex-wrap text-nowrap">
                                    {dataObject?.tags?.map((tag, index) => (
                                        <Link key={index} to={`/tag/${encodeURIComponent(tag.keyword)}`} onClick={doScrollToTop}>
                                            <div key={index} color="indigo" className="text-[#3A32D1] bg-[#3A32D1] bg-opacity-10 font-semibold md:text-[18px] text-xs px-6 md:px-[24px] md:py-3 py-2 rounded-[50px]">{tag.keyword}</div>
                                        </Link>
                                    ))}
                                </div>
                                <div className="article-font px-2 md:px-0">
                                    <CustomBlock data={CustomBlockData} />
                                </div>
                                <div data-testid="fantasy_thank_you" className='p-4 pb-4'>
                                    <div className="flex items-center min-w-[50px]">
                                        <span className="custom-border-left mr-3 md:mr-6"></span>
                                        <p className='text-sm md:text-md text-[#787878]'>Thank You</p>
                                        <span className="custom-border-right ml-3 md:ml-6"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }

            {Boolean(md.mobile()) && (showGoogleAd || Boolean(showParticularArticleAd)) ? <div className="flex justify-center px-2 overflow-hidden">
                <GoogleAdUnit insCustomClass='mt-4' slotProps={SlotProps.News_Details_Page_Square} />
            </div> : null}

            {/* <div className="mt-4 md:mx-0 mx-4 hidden md:block">
                <React.Suspense><MoreStories newsType={newsType} currentNewsID={newsId} /></React.Suspense>
            </div> */}
        </div>
    );
};

export default IndPak;
