import { useEffect } from "react";
import { doHardReloadArticle } from "../constants";

// https://x.com/i/grok/share/HD2A3K0VZ5ktchip5je2g49HN
export const useLoadSocialMedia = (dataObject) => {
  useEffect(() => {
    let twitterScript = null;
    let instaScript = null;

    // Twitter handling
    if (dataObject?.content?.includes('twitter')) {
      twitterScript = document.createElement('script');
      twitterScript.charset = 'utf-8';
      twitterScript.src = `https://platform.twitter.com/widgets.js?version=${doHardReloadArticle ? '' : Math.random() + '_' + Date.now()}`;
      twitterScript.async = true;
      document.body.appendChild(twitterScript);
    }

    // Instagram handling
    if (dataObject?.content?.includes('www.instagram')) {
      instaScript = document.createElement('script');
      instaScript.src = `//www.instagram.com/embed.js?version=${doHardReloadArticle ? '' : Math.random() + '_' + Date.now()}`;
      instaScript.async = true;
      instaScript.onload = () => {
        // Re-process Instagram embeds after script loads
        if (window.instgrm) {
          window.instgrm.Embeds.process();
        }
      };
      document.body.appendChild(instaScript);
    }

    // Cleanup
    return () => {
      twitterScript && document.body.removeChild(twitterScript);
      instaScript && document.body.removeChild(instaScript);
    };
  }, [dataObject]);
};