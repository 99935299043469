import debounce from "lodash.debounce";
import { useEffect, useRef } from "react";
import { getMobileDetect, hideShowFooterHeaderOnScroll } from "../constants";

const md = getMobileDetect();
const isMobile = md.mobile();

export const useShowHideOnScroll = (topRef, bottomRef) => {

    const lastScroll = useRef(0);

    useEffect(() => {
        const debouncedFn = debounce(handleScroll, 200);
        hideShowFooterHeaderOnScroll ? '' : topRef.current.classList.add('sticky');
        hideShowFooterHeaderOnScroll && isMobile && window.addEventListener('scroll', debouncedFn);
        return () => {
            hideShowFooterHeaderOnScroll && isMobile && window.removeEventListener('scroll', debouncedFn);
        };
    }, [isMobile]);

    const handleScroll = () => {
        try {
            const scrollTop = window.scrollY;
            const scrollHeight = document.documentElement.scrollHeight;
            const clientHeight = document.documentElement.clientHeight;
            const atBottom = scrollTop + clientHeight >= scrollHeight - 80;

            if ((scrollTop > 10 && scrollTop < lastScroll.current) || scrollTop === 0 || atBottom) {
                if (topRef.current) {
                    topRef.current.classList.add('moible-top-sticky-navbar');
                    topRef.current.classList.remove('hide-mobile-top-sticky-navbar');
                }
                if (bottomRef.current) {
                    bottomRef.current.classList.add('moible-bottom-sticky-navbar');
                    bottomRef.current.classList.remove('hide-mobile-bottom-sticky-navbar');
                }
            } else {
                /* hide bottom nav bar and top header */
                if (topRef.current) {
                    topRef.current.classList.remove('moible-top-sticky-navbar');
                    topRef.current.classList.add('hide-mobile-top-sticky-navbar');
                }
                if (bottomRef.current) {
                    bottomRef.current.classList.remove('moible-bottom-sticky-navbar');
                    bottomRef.current.classList.add('hide-mobile-bottom-sticky-navbar');
                }
            }
            lastScroll.current = scrollTop;
        } catch (error) {
            // console.error("Error handling scroll:", error);
        }
    };
}

export const useNewDetailToolbarShowHideOnScroll = (newsToolbarRef, newsLiveTagRef) => {

    const newsDetailPageLastScroll = useRef(0);

    useEffect(() => {
        const debouncedFn = debounce(handleNewsDetailScroll, 200);

        hideShowFooterHeaderOnScroll && isMobile && window.addEventListener('scroll', debouncedFn);
        return () => {
            hideShowFooterHeaderOnScroll && isMobile && window.removeEventListener('scroll', debouncedFn);
        };
    }, [isMobile]);

    const handleNewsDetailScroll = () => {
        try {
            const scrollTop = window.scrollY;
            const scrollHeight = document.documentElement.scrollHeight;
            const clientHeight = document.documentElement.clientHeight;
            const atBottom = scrollTop + clientHeight >= scrollHeight - 80;

            if ((scrollTop > 10 && scrollTop < newsDetailPageLastScroll.current) || scrollTop === 0 || atBottom) {
                if (newsToolbarRef.current) {
                    newsToolbarRef.current.classList.add('md:top-[60px]', 'top-[48px]');
                    newsToolbarRef.current.classList.remove('md:top-[60px]', 'top-[0px]');
                }
                if (newsLiveTagRef.current) {
                    newsLiveTagRef.current.classList.add('md:top-[160px]', 'top-[117px]');
                    newsLiveTagRef.current.classList.remove('md:top-[160px]', 'top-[69px]');
                }
            } else {
                if (newsToolbarRef.current) {
                    newsToolbarRef.current.classList.remove('md:top-[60px]', 'top-[48px]');
                    newsToolbarRef.current.classList.add('md:top-[60px]', 'top-[0px]');
                }
                if (newsLiveTagRef.current) {
                    newsLiveTagRef.current.classList.remove('md:top-[160px]', 'top-[117px]');
                    newsLiveTagRef.current.classList.add('md:top-[160px]', 'top-[69px]');
                }
            }
            newsDetailPageLastScroll.current = scrollTop;
        } catch (error) {
            // console.error("Error handling news detail scroll:", error);
        }
    };
}

export const useLiveFilterButtonTopPositionOnScroll = (filterButtonRef) => {
    const schedulePageScroll = useRef(0);

    useEffect(() => {
        const debouncedFn = debounce(handleNewsDetailScroll, 200);

        hideShowFooterHeaderOnScroll && isMobile && window.addEventListener('scroll', debouncedFn);
        return () => {
            hideShowFooterHeaderOnScroll && isMobile && window.removeEventListener('scroll', debouncedFn);
        };
    }, [isMobile]);

    const handleNewsDetailScroll = () => {
        try {
            const scrollTop = window.scrollY;
            const scrollHeight = document.documentElement.scrollHeight;
            const clientHeight = document.documentElement.clientHeight;
            const atBottom = scrollTop + clientHeight >= scrollHeight - 80;

            if ((scrollTop > 10 && scrollTop < schedulePageScroll.current) || scrollTop === 0 || atBottom) {
                if (filterButtonRef.current) {
                    filterButtonRef.current.classList.add('top-[48px]');
                    filterButtonRef.current.classList.remove('top-[0px]');
                }
            } else {
                if (filterButtonRef.current) {
                    filterButtonRef.current.classList.add('top-[0px]');
                    filterButtonRef.current.classList.remove('top-[48px]');
                }
            }
            schedulePageScroll.current = scrollTop;
        } catch (error) {
            // console.error("Error handling news detail scroll:", error);
        }
    };
}

export const useLiveCalenderFilterButtonTopPositionOnScroll = (calenderBarRef, filterButtonRef) => {
    const schedulePageScroll = useRef(0);

    useEffect(() => {
        const debouncedFn = debounce(handleNewsDetailScroll, 200);

        hideShowFooterHeaderOnScroll && isMobile && window.addEventListener('scroll', debouncedFn);
        return () => {
            hideShowFooterHeaderOnScroll && isMobile && window.removeEventListener('scroll', debouncedFn);
        };
    }, [isMobile]);

    const handleNewsDetailScroll = () => {
        try {
            const scrollTop = window.scrollY;
            const scrollHeight = document.documentElement.scrollHeight;
            const clientHeight = document.documentElement.clientHeight;
            const atBottom = scrollTop + clientHeight >= scrollHeight - 80;

            if ((scrollTop > 10 && scrollTop < schedulePageScroll.current) || scrollTop === 0 || atBottom) {
                if (calenderBarRef.current) {
                    calenderBarRef.current.classList.add('top-[48px]');
                    calenderBarRef.current.classList.remove('top-[0px]');
                }
                if (filterButtonRef.current) {
                    filterButtonRef.current.classList.add('top-[110px]');
                    filterButtonRef.current.classList.remove('top-[62px]');
                }
            } else {
                if (calenderBarRef.current) {
                    calenderBarRef.current.classList.add('top-[0px]');
                    calenderBarRef.current.classList.remove('top-[48px]');
                }
                if (filterButtonRef.current) {
                    filterButtonRef.current.classList.add('top-[62px]');
                    filterButtonRef.current.classList.remove('top-[110px]');
                }
            }
            schedulePageScroll.current = scrollTop;
        } catch (error) {
            // console.error("Error handling news detail scroll:", error);
        }
    };
}

export const useScrollToTopButtonPostion = (buttonRef) => {
    const AllPageScroll = useRef(0);

    useEffect(() => {
        const debouncedFn = debounce(handleAllPageScroll, 200);

        hideShowFooterHeaderOnScroll && isMobile && window.addEventListener('scroll', debouncedFn);
        return () => {
            hideShowFooterHeaderOnScroll && isMobile && window.removeEventListener('scroll', debouncedFn);
        };
    }, [isMobile]);

    const handleAllPageScroll = () => {
        try {
            const scrollTop = window.scrollY;
            const scrollHeight = document.documentElement.scrollHeight;
            const clientHeight = document.documentElement.clientHeight;
            const atBottom = scrollTop + clientHeight >= scrollHeight - 80;

            if ((scrollTop > 10 && scrollTop < AllPageScroll.current) || scrollTop === 0 || atBottom) {
                if (buttonRef.current) {
                    buttonRef.current.classList.add('bottom-[144px]');
                    buttonRef.current.classList.remove('bottom-[65px]');
                }
            } else {
                if (buttonRef.current) {
                    buttonRef.current.classList.add('bottom-[65px]');
                    buttonRef.current.classList.remove('bottom-[144px]');
                }
            }
            AllPageScroll.current = scrollTop;
        } catch (error) {
            // console.error("Error handling all page scroll:", error);
        }
    };
}