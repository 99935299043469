import React, { useEffect, useRef, useState, memo } from 'react'
import { formatBlockTime } from '../../../../helpers/general'
import { useHackNx } from '../../../../hooks/useHackNx';
import { headingTag } from '../../../../constants';
import { AnalyticsCategory } from '../../../../helpers/analytics';
import { LiaShareSolid } from 'react-icons/lia';
import useShare from '../../../../hooks/useShare';

const EventBlock = ({ id, blockTime, title, body, showTimeAgo }) => {

    const [fontSize, setFontSize] = useState(document.body.clientWidth > 700 ? 18 : 14);
    const containerRef = useRef();
    const current = containerRef.current;

    const { setNx } = useHackNx({ preFixStr: `Event_Block__${id}` });

    const { showShareButton, handleShareClick } = useShare();

    useEffect(() => {
        let count = 0;
        if (current) {
            try {
                const tableElements = current.getElementsByTagName("table");
                for (let i = 0; i < tableElements.length; i++) {
                    const currentTable = tableElements[i];
                    const wrapper = document.createElement("div");
                    wrapper.className = "table-wrapper";
                    wrapper.style.overflowX = "auto";
                    wrapper.style.width = "100%";
                    currentTable.parentElement.replaceChild(wrapper, currentTable);
                    wrapper.appendChild(currentTable);
                    count++;
                }
                const headingElements = current.querySelectorAll("h1, h2, h3, h4, h5, h6");
                headingElements.forEach(heading => {
                    const tagName = heading.tagName.toLowerCase();
                    const adjustment = headingTag[tagName];
                    heading.style.fontSize = `${fontSize + adjustment}px`;
                    count++;
                });

            } catch (error) {
            } finally {
                console.log(`Event_${id} setting nx to -100, elm updated count - ${count}`)
                setNx(-100);
            }
        }
    }, [current, fontSize, id]);

    return (
        <>
            <div className='font-[Poppins]'>
                <p className='flex items-center gap-3 text-[#000] font-bold text-base md:text-[19px]'>
                    {formatBlockTime(blockTime)}
                    {showTimeAgo && getTimeAgo(blockTime) &&
                        <div className='flex items-center bg-[#3A32D1] rounded-[30px] text-white font-light text-[10px] h-[15px] px-1'>
                            <span className='block h-1 w-1 bg-[#fff] rounded-full shadow-[0px_0px_1px_1px_#FFF] white-animated-dot mr-1'></span>
                            {getTimeAgo(blockTime)}
                        </div>
                    }
                </p>
                <h5 className='text-[15px] md:text-base font-bold italic'>{title}</h5>
                <div
                    ref={containerRef}
                    className={`leading-[${fontSize + 4}] newsContentDiv font-[400] isLive`}
                    style={{ fontSize: `${fontSize}px` }}
                    dangerouslySetInnerHTML={{ __html: body }}
                />
            </div>
            {showShareButton ?
                <button
                    className='text-lg mt-3 border rounded-full h-10 w-10 flex justify-center items-center'
                    onClick={(e) => handleShareClick(e, title, null, `${window.location.href}#${id}`, AnalyticsCategory.EVENTBLOCK, 'event_block')}
                >
                    <LiaShareSolid />
                </button> : null
            }
        </>
    )
}

export default memo(EventBlock);

export const getTimeAgo = (blockTime) => {
    try {
        const now = new Date();
        const timeDiff = Math.floor((now - new Date(blockTime)) / 60000);
        if (timeDiff < 0) {
            return '';
        } else if (timeDiff < 60) {
            return `${timeDiff} min ago`;
        } else if (timeDiff < 1440) {
            const hours = Math.floor(timeDiff / 60);
            const minutes = timeDiff % 60;
            return `${hours} hr ${minutes} min ago`;
        } else {
            return '';
        }
    } catch (error) {
    }
};