import React, { useCallback, useState, useEffect, useRef, useMemo } from "react";
import { Route, Routes, Navigate, useLocation, useParams, useSearchParams } from "react-router-dom";
import Navbar from "src/components/navbar";
import NewsDetails from "./components/pages/NewsDetails";
import Footer from "./components/footer/Footer";
import Series from "./components/pages/Series/Series";
import SelectSeriesPages from "./components/pages/Series/SeriesInstance/SelectSeriesPages";
import Home from "./components/pages/Home/Home";
import Schedule from "./components/pages/Schedule/Schedule";
import Matches from "./components/pages/Schedule/MatchInstance/component";
import MobileNavbar from "./components/navbar/MobileNavbar";
import LoginMain from "./components/pages/Login/LoginMain";
import AdSquare from "./components/common/AdSquare";
import LayoutV1 from "./LayoutV1";
import Teams from "./components/pages/Team/Teams";
import SelectTeamView from "./components/pages/Team/TeamView/SelectTeamView";
import EditProfileForm from "./components/pages/UserProfile/EditProfileForm";
import 'react-toastify/dist/ReactToastify.css';
import NewsV2 from "./components/NewsV2";
import FantasyV2 from "./components/pages/FantasyV2";
import TopRanking from "./components/pages/TopRanking";
import { PointsTable, PointsTableOfSeries } from "./components/pages/PointsTable";
import { AD_CAMPAIGN } from "./helpers/constants";
import MoreNews from "./components/common/MoreNews";
import AppContext, { getDefaultAppContext } from "src/context";
import MobileTopNavbar from "./components/navbar/MobileTopNavbar";
import AboutUs from "./components/pages/AboutUs";
import ContactUsPages from "./components/pages/ContactUs";
import Authors from "./components/pages/Authors";
import CookieConsent from './components/cookieConsent/CookieConsent';

// import SubscribeConsent from "./components/cookieConsent/SubscribeConsent";
import AuthorsInstance from "./components/pages/Authors/AuthorsInstance";
import CustomPage from "./components/pages/common/CustomPage";
import GoogleAdUnit from "./components/common/GoogleAdUnit";
import { getMobileDetect, NO_INTERNET_CONT, NO_INTERNET_MESSAGE, showGoogleAd, SlotProps } from "./constants";
import { IoClose } from "react-icons/io5";
import debounce from "lodash.debounce";
import { useShowHideOnScroll } from "./hooks/useShowHideOnScroll";
import Shorts from "./components/pages/shorts/Shorts";
import ScrollToTopButton from "./components/common/ScrollToTopButton";
import PlayersProfile from "./components/pages/PlayerDetails/PlayersDetails";
import ShortsInstance from "./components/pages/shorts/ShortsInstance";
import usePost from "./hooks/usePost";
import { getSessionId } from "../axiosInstance";
import OneSignal from 'react-onesignal';
import MoreCategories from "./components/pages/MoreCategories";
import IndPak from "./components/pages/NewsDetails/IndPak";
import LiveBlog from "./components/pages/NewsDetails/liveBlogs/LiveBlog";
import { LiveBlogTests } from "./components/pages/NewsDetails/liveBlogs/LiveBlogTests";
import useOnlineStatus from "./hooks/useOnlineStatus";
import PurgeCache from "./components/PurgeCache";

function get1SignalAppId() {
  if (window.location.host === "cricketgully.com") {
    // prod
    return '11c18f2b-f53c-4921-bc70-9cdf197a724b';
  }

  // dev
  return '67668c7e-3198-48ef-a54f-5c04a5280b8b';
}
const ConditionalRoute = () => {
  const { id: slug } = useParams();
  const memoizedValues = useMemo(() => {
    const parts = slug.split("-");
    const newsId = parts[parts.length - 1];
    return { newsId };
  }, [slug]);
  const { newsId } = memoizedValues;

  switch (newsId) {
    case "3558":
      return <IndPak />
    case "3674":
    case "3720":
    case "3766":
      return <LiveBlog />
    default:
      break;
  }
  return <NewsDetails />;
};
// replace from match to Schedule
const RedirectToMatchInfo = () => {
  const { seriesId, matchesId } = useParams();
  return <Navigate to={`/series/${seriesId}/matches/${matchesId}/matchinfo`} replace />;
};
const RedirectToNewPath = () => {
  const { seriesId, matchesId, type } = useParams();
  return <Navigate to={`/series/${seriesId}/schedule/${matchesId}/${type}`} replace />;
};
const App = () => {
  const location = useLocation();
  const pathname = location.pathname;
  const isLoginPage = pathname.toLowerCase().includes("/login");
  const isUserFormPage = pathname.toLowerCase().includes("/user-profile");
  const [closeMobileAd, setCloseMobileAd] = useState(false);
  const [searchParams] = useSearchParams();
  const { makeRequest } = usePost();

  useEffect(() => {
    // Ensure this code runs only on the client side
    if (typeof window !== 'undefined') {
      OneSignal.init({
        appId: get1SignalAppId(),
        // You can add other initialization options here
        notifyButton: {
          enable: false,
        },
        // Uncomment the below line to run on localhost. See: https://documentation.onesignal.com/docs/local-testing
        allowLocalhostAsSecureOrigin: true
      });
    }
  }, []);

  const md = getMobileDetect();

  const mobileTopNavbarContainerRef = useRef(null);
  const mobileBottomNavbarContainerRef = useRef(null);
  useShowHideOnScroll(mobileTopNavbarContainerRef, mobileBottomNavbarContainerRef);
  const { isOnline } = useOnlineStatus();

  if (!isOnline) {
    return (
      <div
        style={{
          width: '100%', height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center'
        }}
      >
        <h3>{NO_INTERNET_MESSAGE}</h3>
        <p>{NO_INTERNET_CONT}</p>
      </div>
    )
  }

  return (
    <div className="min-h-screen flex flex-col ">
      <ScrollToTopButton />
      <div>
        {/* <SubscribeConsent/> */}
        {/* <React.Suspense> */}
        <CookieConsent />
        {/* </React.Suspense> */}
      </div>
      {/* <div className="md:block hidden">
        {!isLoginPage && !isUserFormPage && <AdSquare campaign={AD_CAMPAIGN.HOME_TOP_BANNER} />}
      </div> */}
      <div
        className={`sticky -top-1 z-50 hidden md:block`}
      >
        <Navbar />
      </div>
      <div
        ref={mobileTopNavbarContainerRef}
        className={`-top-1 z-50 md:hidden ${(isLoginPage || isUserFormPage) ? "hidden md:block" : ""} `}
      >
        <MobileTopNavbar />
      </div>
      <div className="flex-grow">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/p/:slug" element={<CustomPage />} />
          <Route path="*" element={<Navigate to="/" />} />
          <Route path="/user-profile" element={<EditProfileForm />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/contact-us" element={<ContactUsPages />} />
          <Route path="/authors" element={<Authors />} />
          <Route path="/authors/:username" element={<AuthorsInstance />} />
          <Route path="/login" element={<LoginMain />} />
          <Route path="/cgfToken" element={<CGFToken />} />
          <Route path="/purge-cache" element={<PurgeCache />} />
          <Route path="/web-stories/:id" element={<ShortsInstance />} />
          <Route element={<LayoutV1 />}>
            <Route path="/players-details/:playerSlug" element={<PlayersProfile />} />
            <Route path="/players-details/:playerSlug/:playerSection" element={<PlayersProfile />} />
            <Route path="/points-table" element={<PointsTable />} />
            <Route path="/points-table/:seriesSlug" element={<PointsTableOfSeries />} />
            <Route path="/ranking" element={<TopRanking />} />
            <Route path="/ranking/:type" element={<TopRanking />} />
            <Route path="/ranking/:type/:subType" element={<TopRanking />} />
            <Route path='/news' element={<Navigate to="/cricket-news" />} />
            <Route path="/news/:path" element={<RedirectNewsToCricketNews />} />
            <Route path="/cricket-news" element={<NewsV2 />} />
            <Route path="cricket-top-story" element={<NewsV2 />} />
            <Route path="/live-blogs" element={<NewsV2 />} />
            <Route path="/schedule/:type" element={<Schedule />} />
            <Route path="/web-stories" element={<Shorts />} />

            {/* redirect if 'type' is missing, defaulting to 'matchinfo' */}
            <Route path="/series/:seriesId/schedule/:matchesId" element={<RedirectToMatchInfo />} />
            {/* replace /matches to /schedule */}
            <Route path="/series/:seriesId/matches/:matchesId/:type" element={<RedirectToNewPath />} />
            <Route path="/series/:seriesId/schedule/:matchesId/:type" element={<Matches />} />

            <Route path="/fantasy" element={<Navigate to="/fantasy-cricket-tips" replace />} />
            <Route path="/fantasy-cricket-tips" element={<FantasyV2 />} />
            {/* <Route path="/fantasyv2" element={<FantasyV2 />} /> */}
            <Route path="/cricket-news/:id" element={<ConditionalRoute />} />
            <Route path="/live-blogs/:id" element={<LiveBlog />} />
            <Route path="/live-blogs-test/:id" element={<LiveBlogTests />} />
            <Route path="/fantasy-cricket-tips/:id" element={<NewsDetails />} />
            <Route path="/cricket-top-story/:id" element={<NewsDetails />} />
            <Route path="/teams" element={<Teams />} />
            <Route path="/teams/:id/:type" element={<SelectTeamView />} />
            <Route path="/series" element={<Series />} />
            <Route path="/series/:id" element={<Navigate to="schedule" replace />} />
            <Route path="/series/:id/:type" element={<SelectSeriesPages />} />
            <Route path="/series/:slug/:type/:team" element={<SelectSeriesPages />} />
            <Route path="/category/:category_slug" element={<MoreNews />} />
            <Route path="/tag/:tag" element={<NewsV2 />} />
            <Route path="/more-categories" element={<MoreCategories />} />
          </Route>
        </Routes>
      </div>
      {!isLoginPage && !isUserFormPage && <Footer />}
      {!isLoginPage && !isUserFormPage && (
        <div style={{ zIndex: 10000 }} className="sticky -bottom-1 md:hidden z-40">
          {md.mobile() && !closeMobileAd && showGoogleAd ?
            <div className="relative flex items-center justify-center bg-[#000000bf]">
              <GoogleAdUnit onHide={() => setCloseMobileAd(true)} slotProps={SlotProps.Mobile_Above_Footer_Nav} />
              <button onClick={() => setCloseMobileAd(true)} className="absolute -top-2.5 right-0 bg-[#312AB7] text-[#fff] rounded-full h-5 w-5 flex justify-center items-center"><IoClose /></button>
            </div>
            : null
          }
          <MobileNavbar navContainerRef={mobileBottomNavbarContainerRef} />
        </div>
      )}
      <input
        data-ad-slot1="Ghanana"
        id="cricketGullyApiBaseUrl"
        type="hidden"
        value={process.env.API_BASE_URL}
        data-env={process.env.NODE_ENV}
      />
      <input
        data-ad-slot1="Ghanana_SENTRY_AUTH_TOKEN"
        id="SENTRY_AUTH_TOKEN"
        type="hidden"
        data-app-enb={process.env.APP_ENV}
        value={process.env.SENTRY_AUTH_TOKEN}
        data-env={process.env.NODE_ENV}
      />

    </div>
  );
};

const AppWrapper = () => {
  const [appContextState, setAppContextState] = useState(getDefaultAppContext());

  const updateUrlInfo = useCallback((updatedInfo) => {
    setAppContextState(prevState => {
      const pathName = window.location.pathname;
      const currentUrlInfo = prevState.urlInfoByKey[pathName] || {};
      return {
        ...prevState,
        urlInfoByKey: {
          ...prevState.urlInfoByKey,
          [pathName]: {
            ...currentUrlInfo,
            ...updatedInfo,
          }
        },
      };
    })
  }, []);

  const updatePlayerInfo = useCallback((data, isLoading, error, mutate) => {
    setAppContextState(prevState => ({
      ...prevState,
      playerInfo: {
        data: data,
        isLoading: isLoading,
        error: error,
        mutate: mutate,
      },
    }))
  }, [])


  return (
    <AppContext.Provider value={{
      state: appContextState,
      updateUrlInfo,
      updatePlayerInfo
    }}>
      <App />
    </AppContext.Provider>
  )
}
export default AppWrapper;

function RedirectNewsToCricketNews(props) {
  const { path = '' } = useParams(); // Extract the dynamic part of the path
  const url = `/cricket-news/${path}`;
  window.location.href = url;
}


function CGFToken() {
  return (
    <>
      <h1 className="p-10 border m-2 border-gray-400">{localStorage.getItem("cg_f_base_token") || ' ------- '}</h1>
      <br />
      <br />
      <h1 className="p-10 border m-2 border-gray-400">{getSessionId()}</h1>
      <br />
      <br />
      <h1 className="p-10 border m-2 border-gray-400">1Signal App Id : {get1SignalAppId()}</h1>
      <br />
    </>)
}