import React, { useState } from 'react'
import { ShimmerEffect } from '../../common/ShimmerEffect';
import { Link } from 'react-router-dom';
import { useAxiosSWR } from '../../../../axiosInstance';
import ShowShorts from './ShowShorts';
import { resizedImageURL, srcsetImageURL } from '../../../helpers/general';

export default function Shorts() {

    const { data, isLoading, error } = useAxiosSWR('/v1/stories');
    const [isOpen, setIsOpen] = useState(false);
    const [activeId, setActiveId] = useState(null);

    if (isLoading) {
        return <div className='flex justify-center items-center w-full h-screen text-xl'>Loading...</div>
    }

    if (error) {
        return <div className='flex justify-center items-center w-full h-screen text-xl'>something went wrong </div>
    }

    return (
        <>
            <div className='mt-2 md:mt-4 md:mx-0 mx-[6px] bg-white border md:rounded-2xl rounded-xl'>
                <div className='md:min-h-[18px] md:min-w-[180px] px-5 md:py-8 py-4 min-w-[50px] text-[24px] leading-[28px] text-[#000000] font-bold flex items-center'>
                    <span className="custom-border-left mr-3 md:mr-6"></span>
                    <h1 className='text-lg md:text-2xl'>Popular Stories</h1>
                    <span className="custom-border-right ml-3 md:ml-6"></span>
                </div>
                <div className="flex flex-wrap items-stretch md:px-3 md:mb-4">
                    {data && data.length > 0 ? data.map((item) => {
                        const firstMedia = item?.stories?.[0]?.media?.[0];
                        const mediaType = item?.stories?.[0]?.type;
                        const slug = item?.info?.slug ? `${item?.info?.slug}-${item.id}` : item.id;
                        return (
                            <div
                                key={item.id}
                                className="block md:flex w-1/2 lg:w-1/4 h-[315px] md:h-[400px] p-[6px] md:p-[10px]"
                            // onClick={() => { setIsOpen(true), setActiveId(item.id) }}
                            >
                                <Link to={`/web-stories/${slug}`} target='_blank' rel="noopener noreferrer" className='h-full w-full'>
                                    <div className="shorts-box relative rounded-xl md:rounded-[28px] overflow-hidden cursor-pointer h-full w-full">
                                        {mediaType === 'image' ? (
                                            <ShimmerEffect
                                                src={resizedImageURL(firstMedia?.url,"170x315")}
                                                srcSet={srcsetImageURL(firstMedia?.url,"170x315","228x400")}
                                                alt='shorts-thumb'
                                                className='w-full h-full rounded-xl md:rounded-[28px] object-center'
                                            />
                                        ) : (
                                            <video
                                                src={firstMedia?.url}
                                                className="w-full h-full rounded-xl md:rounded-[28px] object-cover"
                                            />
                                        )}
                                        <div className="absolute bottom-0 w-full z-10 p-2 md:p-5">
                                            <p className="text-white text-[12px] md:text-[15px] font-medium leading-tight">
                                                {item?.title}
                                            </p>
                                            <div className='flex gap-1 mt-3'>
                                                {item.stories.map((media) => (
                                                    <div key={media.id} className='bg-white h-1 rounded-lg' style={{width : `${100 / item.stories.length}%`}} />
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        );
                    }) : <div>No stories available</div>}
                </div>
            </div>
            {isOpen ? <ShowShorts storyData={data} activeId={activeId} setActiveId={setActiveId} setIsOpen={setIsOpen} /> : null}
        </>
    )
}
