import React from "react";
import { useAxiosSWR } from "../../../axiosInstance";
import { ShimmerEffect } from "./ShimmerEffect";
import { getTimeLabelForPastDateTime } from "../../helpers/dates";
import { Link } from "react-router-dom";
import { doScrollToTop, getNewsDetailsLinkPath, getSanitizedArray, resizedImageURL } from "../../helpers/general";
import { doHardReloadArticle } from "../../constants";
import CGLink from "./CGLink";
const shimmerAry = []
for (let i = 0; i < 4; i++) {
    shimmerAry.push({ isShimmer: true, slug: "", id: "" })
}
const Articles = () => {
    const { data, isLoading, error } = useAxiosSWR("/v1/news/articles");
    if (error) {
        return <div></div>;
    }
    const articles = getSanitizedArray(isLoading ? shimmerAry : data);

    return (
        <div className="">
            <h2 className='mb-4 mt-2 text-lg leading-5 font-bold text-center'>Articles</h2>
            {articles.map((obj, i) => (
                <div className={(i === articles.length - 1 ? "border-none" : "border-b")} key={i}>
                    <CGLink
                        doHardRedirect={doHardReloadArticle}
                        to={getNewsDetailsLinkPath(obj.slug, obj.id, obj.type, obj)}
                        onClick={doScrollToTop}
                    >
                        <div className="my-2 grid grid-cols-7 gap-2 h-[72px]">
                            <div className="col-span-2 md:col-span-1 lg:col-span-2 md:pt-0 pt-1">
                                {obj.isShimmer ? <div className="w-16 h-16 md:pt-1 pt-0 rounded-[14px] bg-gray-300 loading"></div> :
                                    <ShimmerEffect src={resizedImageURL(obj.image, "128x128")} alt="newsImage" className="md:pt-[3px] pt-0 rounded-[14px] h-16 w-16" />}
                            </div>
                            {obj.isShimmer ?
                                <div className="col-span-5 md:col-span-6 lg:col-span-5 h-[72px] bg-gray-300 loading"></div> :
                                <div className="col-span-5 md:col-span-6 lg:col-span-5">
                                    <p
                                        className="md:text-sm text-xs font-semibold news-title"
                                        dangerouslySetInnerHTML={{ __html: obj.title }}
                                    />
                                    <p className="md:text-xs text-[10px] text-[#6C6C6C] line-clamp-1">
                                        {obj.excerpt}
                                    </p>

                                    <p className="md:text-xs text-[10px] text-opacity-60 text-[#6C6C6C]">
                                        {getTimeLabelForPastDateTime(obj.date)}
                                    </p>
                                </div>}
                        </div>
                    </CGLink>
                </div>
            ))}
        </div>
    );
};

export default React.memo(Articles);
