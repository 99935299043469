import React, { memo } from "react";
import TaboolaFlush from "./TaboolaFlush";
import TaboolaHead from "./TaboolaHead";
import TaboolaWidget from "./TaboolaWidget";

const HideTaboolaAd = true;
const TaboolaAds = ({ pageType, containerId, mode, placement }) => {
    if (HideTaboolaAd) {
        return null;
    }
    const isProd = window.location.hostname === "cricketgully.com";
    return (
        <>
            {!isProd && <div className="flex align-center justify-center">Taboola Ads for {placement}</div>}
            <TaboolaHead pageType={pageType} />
            <TaboolaWidget
                containerId={containerId}
                mode={mode}
                placement={placement}
            />
            <TaboolaFlush />
        </>
    );
};

export default memo(TaboolaAds);
