import React, { useState, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Articles from "./components/common/Articles";
import ExclusivelyVideos from "./components/pages/Home/common/ExclusivelyVideos";
import { LiveMatchesCarousel } from "./components/pages/Home/common/liveMatchesCarousel/component";
import AdSquare from "./components/common/AdSquare";
import { AD_CAMPAIGN } from "./helpers/constants";
import { getMobileDetect, showGoogleAd, SlotProps } from "./constants";
import GoogleAdUnit from "./components/common/GoogleAdUnit";
import PlayerDetailsBanner from "./components/pages/PlayerDetails/common/PlayerDetailsBanner";
import TaboolaAds from "./components/taboola/TaboolaAds";

const IS_CATEGORY_URL = ["/schedule/live", "/schedule/upcoming", "/schedule/results", "/web-stories", "/series", "/points-table", "/cricket-news", "/cricket-top-story", "/fantasy-cricket-tips", "/live-blogs"];
const normalizePath = (path) => path.replace(/\/$/, "");

const LayoutV1 = () => {
  const location = useLocation();
  const pathname = location.pathname.toLowerCase();
  const [adCampaign, setAdCampaign] = useState("");
  const isCategoryTaboolaAds = Boolean(IS_CATEGORY_URL.some((url) => normalizePath(pathname) === url));

  useEffect(() => {
    if (pathname.startsWith("/schedule")) {
      setAdCampaign(AD_CAMPAIGN.SCHEDULE_SQUARE);
    } else if (pathname.startsWith("/cricket-news")
      || pathname.startsWith("/cricket-top-story")
      || pathname.startsWith("/fantasy-cricket-tips")) {
      if (pathname === "/cricket-news/" || pathname === "/cricket-news") {
        setAdCampaign(AD_CAMPAIGN.NEWS_SQUARE);
      } else {
        setAdCampaign(AD_CAMPAIGN.NEWS_DETAILS_SQUARE);
      }
    } else if (pathname.startsWith("/fantasy")) {
      setAdCampaign(AD_CAMPAIGN.FANTASY_SQUARE);
    } else if (pathname.startsWith("/series")) {
      if (pathname.includes("series/")) {
        if (pathname.includes("matches/")) {
          setAdCampaign(AD_CAMPAIGN.MATCH_DETAILS_SQUARE);
        } else {
          setAdCampaign(AD_CAMPAIGN.SERIES_DETAILS_SQUARE);
        }
      } else {
        setAdCampaign(AD_CAMPAIGN.SERIES_SQUARE);
      }
    } else if (pathname.startsWith("/ranking")) {
      setAdCampaign(AD_CAMPAIGN.RANKING_SQUARE)
    } else if (pathname.startsWith("/points-table")) {
      setAdCampaign(AD_CAMPAIGN.POINTS_TABLE)
    } else if (pathname.includes("/teams")) {
      setAdCampaign(AD_CAMPAIGN.TEAMS_SQUARE)
    } else if (pathname.startsWith("/category")) {
      setAdCampaign(AD_CAMPAIGN.CATEGORY_SQUARE)
    } else if (pathname.startsWith("/tag")) {
      setAdCampaign(AD_CAMPAIGN.TAG_SQUARE)
    }
  }, [pathname]);

  const isShowLiveMatchCarousel = pathname.includes("schedule/") || (pathname.includes("series/") && pathname.includes("matches/"));
  const isShowPlayerDetailsBanner = pathname.includes("players-details");

  const md = getMobileDetect();
  const isMobile = Boolean(md.mobile())
  const isNotMobile = !isMobile;

  const isDesktopNewsDetails = isNotMobile && (adCampaign === AD_CAMPAIGN.NEWS_DETAILS_SQUARE || adCampaign === AD_CAMPAIGN.NEWS_SQUARE);
  return (
    <div className="md:mb-28 mb-4">
      <div className="bg-[#2F27BE]">
        <div className="max-w-[1320px] xl:mx-auto">
          {isShowLiveMatchCarousel &&
            <div className="md:block hidden">
              <LiveMatchesCarousel />
            </div>
          }
        </div>
      </div>
      {isShowPlayerDetailsBanner ?
        <PlayerDetailsBanner />
        : null}
      {isDesktopNewsDetails && showGoogleAd ? <div className="flex justify-center">
        <GoogleAdUnit insCustomClass="max-w-[1320px] md:mx-4 xl:mx-auto md:mt-4" slotProps={SlotProps.Desktop_Above_Article} />
      </div>
        : null}
      <div className="max-w-[1320px] md:mx-4 xl:mx-auto lg:grid lg:grid-cols-4 lg:gap-0 gap-6 md:mt-4">
        <main className="col-span-3 lg:mr-6 mr-0 outlet-container min-h-screen">
          <Outlet />
          <div data-testid="taboola_ad_container" className='mt-6 md:mx-0 mx-[6px]'>
              <TaboolaAds
                  // key={isCategoryTaboolaAds ? 'category-ads' : 'article-ads'}
                  key={pathname}
                  pageType={isCategoryTaboolaAds ? 'category' : 'article'}
                  containerId={isCategoryTaboolaAds ? 'taboola-below-category-thumbnails' : 'taboola-below-article-thumbnails'}
                  mode='alternating-thumbnails-a'
                  placement={isCategoryTaboolaAds ? 'Below Category Thumbnails' : 'Below Article Thumbnails'}
              />
          </div>
        </main>
        <aside className="col-span-1 hidden md:block">
          {isNotMobile && showGoogleAd ?
            <div className="flex justify-center items-center">{/* min-h-[290px] mt-4 */}
              <GoogleAdUnit insCustomClass="mt-4" slotProps={SlotProps.News_Details_Page_Square} />
            </div> : null
          }
          <div className="bg-white rounded-xl border p-4 md:my-4 md:mx-0 m-4 md:min-h-[425px]">
            <Articles />
          </div>
          {isDesktopNewsDetails && showGoogleAd ?
            <div className="mx-0"> {/* flex justify-center */}
              <GoogleAdUnit insCustomClass='flex justify-center' slotProps={SlotProps.Desktop_Asides_2} /></div> : null}
          <div className="bg-white rounded-xl border md:mx-0 mx-4 mt-4 md:min-h-[990px]">
            <ExclusivelyVideos />
          </div>
          {isDesktopNewsDetails && showGoogleAd ?
            <div className="flex justify-center sticky top-16">
              <GoogleAdUnit insCustomClass='mx-0 mt-4' slotProps={SlotProps.Desktop_Asides_2} /></div> : null}
        </aside>
      </div>
    </div>
  );
};

export default LayoutV1;
