import React from 'react'
import { useAxiosSWR } from '../../../axiosInstance';
import { doScrollToTop, getAssetUrl } from '../../helpers/general';
import { Link } from 'react-router-dom';

const rightArrow = getAssetUrl("icons-footer/menu-right-arrow.svg");

export default function MoreCategories() {

    const { data: moreDropdownData, isLoading, error: isError } = useAxiosSWR("/v1/news/categories");

    if (isLoading) {
        return <div>Loading...</div>
    }

    if (isError) {
        return (
            <div className='flex justify-center w-full mt-16'>
                <div className="text-center">
                    <h2>Something went wrong.</h2>
                    <p>Please click on below button to go to home page</p>
                    <a href="/">
                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4">
                            Go to Home
                        </button>
                    </a>
                </div>
            </div>
        )
    }

    return (
        <>
            <div className='relative more-categories-bg px-6 pt-3'>
                {moreDropdownData && moreDropdownData.length > 0 && (
                    moreDropdownData?.map((item) => (
                        <Link
                            key={item.id}
                            to={`/category/${item.category_slug}`}
                            onClick={() => {
                                doScrollToTop();
                            }}
                            className='block my-3'
                        >
                            <div className="flex justify-between items-center h-[56px] shadow-sm bg-white rounded-xl px-4">
                                <p className="text-[##051235] text-base font-medium">{item.name}</p>
                                <img src={rightArrow} alt="menu-right-arrow" />
                            </div>
                        </Link>
                    ))
                )}
            </div>
        </>
    )
}
