import React, { useState } from 'react'
import { useAxiosSWR } from '../../axiosInstance';
import { doScrollToTop } from '../helpers/general';

export default function ArticleCategoryIntroduction({ category_slug, hideArticleIntroduction }) {

    const [seeMore, setSeeMore] = useState(false);

    const { data, error } = useAxiosSWR(`${category_slug ? `v1/misc/customPage/${category_slug.toLowerCase()}` : null}`);

    if (error) {
        hideArticleIntroduction && hideArticleIntroduction();
    }
    
    if (!category_slug) {
        hideArticleIntroduction && hideArticleIntroduction();
        return;
    }

    if (data?.status === 'publish' && data?.content) {
        return (
            <>
                <div className='rounded-2xl md:border md:my-4 mb-4 bg-white md:mx-0 mx-[6px] mt-[6px]'>
                    <div className='px-4 md:pt-10 md:px-10 article-font relative'>
                        <h1 className='md:text-3xl font-semibold text-lg pt-4 md:pt-2 pb-3 md:leading-[32px] leading-[27px]'>
                            {data?.title}
                        </h1>
                        <span className="block border-b py-1"></span>
                        <div
                            className={`newsContentDiv my-4 text-ellipsis overflow-hidden ${seeMore ? '' : 'line-clamp-4 md:line-clamp-3'}`}
                            dangerouslySetInnerHTML={{ __html: data?.content }}
                        />
                    </div>
                    <div className='flex items-end justify-center h-2 w-full bg-gradient-to-t from-[#e2e2e2ab] to-transparent'>
                    </div>
                    <div className='text-[#3A32D1] text-center py-3 md:text-sm text-xs font-semibold'>
                        <button onClick={() => {
                            if (seeMore) {
                                doScrollToTop();
                                setSeeMore(false);
                            } else {
                                setSeeMore(true);
                            }
                        }}>
                            {seeMore ? 'See Less' : 'See More'}
                        </button>
                    </div>
                </div>
            </>
        )
    }
}
