import React from 'react'

export default function AdsImage({ desktopImage, mobileImage, redirectURL }) {

    const handleClick = (e) => {
        e.preventDefault();
        window.open(window.location.href, "_blank");
        window.location.href = redirectURL;
    };

    return (
        <>
            <div className="cursor-pointer" onClick={handleClick}>
                <img
                    src={mobileImage}
                    alt="Mobile View"
                    className="block sm:hidden w-full"
                />
                <img
                    src={desktopImage}
                    alt="Desktop View"
                    className="hidden sm:block w-full"
                />
            </div>
        </>
    );
}

AdsImage.defaultProps = {
    desktopImaga: '',
    mobileImage: '',
    redirectURL: '#'
}