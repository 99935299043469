import React, { useContext } from 'react'
import { ShimmerEffect } from '../../../common/ShimmerEffect'
import { getAssetUrl } from '../../../../helpers/general'
import { useNavigate, useParams } from 'react-router-dom'
import AppContext from '../../../../context';

const PlayerDetailsBannerImg = getAssetUrl('player-details-banner.png');
const PlayerDetailsBannerMobileImg = getAssetUrl('player-details-banner-mobile.png');
const CricketBatIcon = getAssetUrl('cricket-bat.svg');
const defaultSrc = "https://static.cricketgully.com/filters:grayscale()/filters:blur(6)/cricketgully-logo.jpg";

export default function PlayerDetailsBanner() {
    const { state } = useContext(AppContext);
    const { playerSlug } = useParams();
    const { playerSection } = useParams();
    const navigate = useNavigate();

    const { data, isLoading, error, mutate } = state.playerInfo;
    const playerInfo = data?.info || {};
    const countryInfo = data?.country || {};
    const playerImage = data?.logo_url || defaultSrc;

    if (isLoading) {
        return <div className='flex justify-center items-center w-full h-screen text-xl'>Loading...</div>
    }

    if (error) {
        return (
            <div className='flex flex-col justify-center items-center w-full h-full text-xl text-center'>
                something went wrong fetching Player Details
                <button onClick={mutate} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4 w-fit mx-auto text-lg">
                    Try Again
                </button>
            </div>
        )
    }

    return (
        <div
            className="flex items-center h-[433px] md:h-[300px] w-full relative bg-cover bg-center md:bg-cover md:bg-center md:px-[80px] lg:px-[140px] xl:px-[180px]"
            style={{
                backgroundImage: `url(${window.innerWidth >= 768 ? PlayerDetailsBannerImg : PlayerDetailsBannerMobileImg})`
            }}
        >
            <div className="md:flex items-center w-full">
                <div className='flex justify-center items-center w-full md:w-[222px]'>
                    <div className='relative h-[152px] w-[152px] md:h-[222px] md:w-[222px] bg-[#2C1D18] rounded-full'>
                        <ShimmerEffect src={playerImage} alt='player-img' className='rounded-full h-full w-full' />

                        <div className='flex justify-center items-center absolute bottom-0 right-0 h-[40px] w-[40px] md:h-[60px] md:w-[60px] rounded-full shadow-[0px_5px_10px_0px_#00000040]' style={{ background: 'linear-gradient(180deg, #F5E2B7 0%, #CD9D52 100%)', }}>
                            <span className='text-black font-extrabold text-lg md:text-[26px]'>C</span>
                        </div>
                    </div>
                </div>
                <div className='w-full md:pl-10 mt-3 md:mt-0'>
                    <div className='text-center md:text-left'>
                        <h1 className='text-white font-bold text-2xl md:text-6xl'>{data?.name || '-'}</h1>
                        <div className='flex justify-center md:justify-start items-center gap-4 mt-3'>
                            <div className='flex items-center gap-3'>
                                <img src={countryInfo?.image || defaultSrc} alt="flag" className='w-4 h-4 md:w-5 md:h-5' />
                                <span className='text-[#ffffff99] text-base md:text-2xl font-semibold'>{countryInfo?.name || '-'}</span>
                            </div>
                            <div className='text-[#ffffff99]'>•</div>
                            <div className='flex items-center gap-3'>
                                <span className='text-[#ffffff99] text-base md:text-2xl font-semibold'>Batter</span>
                                <img src={CricketBatIcon} alt="icon" className='w-4 h-4 md:w-5 md:h-5' />
                            </div>
                        </div>
                        <div className='flex justify-center md:justify-start items-center gap-4 mt-3'>
                            <div>
                                <h6 className='text-white font-bold italic'>4 <sup className='text-[#ffffff99] font-normal'>th</sup></h6>
                                <p className='text-[#ffffff99] font-medium uppercase'>T20</p>
                            </div>
                            <div>
                                <h6 className='text-white font-bold italic'>3 <sup className='text-[#ffffff99] font-normal'>rd</sup></h6>
                                <p className='text-[#ffffff99] font-medium uppercase'>ODI</p>
                            </div>
                            <div>
                                <h6 className='text-white font-bold italic'>220 <sup className='text-[#ffffff99] font-normal'>th</sup></h6>
                                <p className='text-[#ffffff99] font-medium uppercase'>TEST</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Tabs */}
                <div className='md:hidden bg-white p-2 rounded-[16px] md:mx-0 mx-[6px] mt-12'>
                    <div className='grid text-center p-[1px] items-center grid-cols-3 gap-2'>
                        <button
                            className={`text-[13px] text-black font-medium border-2 rounded-[14px] py-2 ${playerSection === 'overview' ? 'bg-[#3A32D1] text-white border-[#3A32D1]' : 'border-[#eee]'}`}
                            onClick={() => {
                                navigate(`/players-details/${playerSlug}/overview`)
                            }}
                        >
                            Overview
                        </button>
                        <button
                            className={`text-[13px] text-black font-medium border-2 rounded-[14px] py-2 ${playerSection === 'stats' ? 'bg-[#3A32D1] text-white border-[#3A32D1]' : 'border-[#eee]'}`}
                            onClick={() => {
                                navigate(`/players-details/${playerSlug}/stats`)
                            }}
                        >
                            Stats
                        </button>
                        <button
                            className={`text-[13px] text-black font-medium border-2 rounded-[14px] py-2 ${playerSection === 'news' ? 'bg-[#3A32D1] text-white border-[#3A32D1]' : 'border-[#eee]'}`}
                            onClick={() => {
                                navigate(`/players-details/${playerSlug}/news`)
                            }}
                        >
                            News
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
